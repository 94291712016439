import AgentChatComponent from "./agent_chat_component";
import React, { useEffect, useRef, useState } from 'react';
import { MessageCircle, X } from 'lucide-react';
import { useHotkeys } from 'react-hotkeys-hook';

const ChatHead = ({dataUrl}) => {
  const [isOpen, setIsOpen] = useState(false);
  const chatBoxRef = useRef(null);
  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  useHotkeys("esc", () => {
    setIsOpen(false)
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={toggleChat}></div>
      )}
      <div className="fixed bottom-[69px] right-3 md:bottom-5 md:right-5 z-50">
        {isOpen ? (
          <div ref={chatBoxRef} className="bg-zinc-900 rounded-lg overflow-hidden shadow-md shadow-black w-full h-1/3 md:w-[600px] md:h-1/3 flex flex-col">
            <div className="flex justify-between items-center px-4 p-2 border-b border-t border-zinc-800">
              <h3 className="text-zinc-400">Chat</h3>
              <button onClick={toggleChat} className="text-zinc-500 hover:text-zinc-300">
                <X className="w-6 h-6" />
              </button>
            </div>
            <AgentChatComponent dataUrl={dataUrl}/>
          </div>
        ) : (
          <button onClick={toggleChat}
                  className="bg-dgreen-500 hover:bg-dgreen-300 text-white rounded-full p-3 shadow-lg shadow-dgreen-300/40 transition-all duration-200 ease-in-out transform hover:scale-110">
            <MessageCircle className="w-8 h-8" />
          </button>
        )}
      </div>
    </>
  );
};

export default ChatHead;