import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="positions"
export default class extends Controller {
  static targets = ['defaultTableHeader', 'actionTableHeader']

  connect() {
  }

  closeSelected(event) {
    this.openNewTrade("close")
  }

  openNewTrade(openOrClose) {
    let url = `${this.element.dataset.newTradeUrl}?order_type=OPTION`

    let elements = this.element.querySelectorAll("input[type=\"checkbox\"]:checked")
    elements.forEach((element) => {
      let quantity = parseInt(element.dataset.quantity)
      let order_side
      if (openOrClose === "open") {
        order_side = quantity < 0 ? "sell_to_open" : "buy_to_open"
      } else {
        order_side = quantity > 0 ? "sell_to_close" : "buy_to_close"
      }

      if (!url.includes("symbol=")) {
        url = `${url}&symbol=${element.dataset.symbol}`
      }

      url = `${url}&option_symbol[]=${element.dataset.optionSymbol}`
      url = `${url}&quantity[]=${Math.abs(quantity)}`
      url = `${url}&order_side[]=${order_side}`
    })
    // console.log(url)
    Turbo.visit(url)
  }


  addSelected(event) {
    this.openNewTrade("open")
  }

  positionSelected(event) {
    let elements = this.element.querySelectorAll("input[type=\"checkbox\"]:checked")
    if (elements.length > 0) {
      this.defaultTableHeaderTarget.classList.add("hidden")
      this.actionTableHeaderTarget.classList.remove("hidden")
    } else {
      this.actionTableHeaderTarget.classList.add("hidden")
      this.defaultTableHeaderTarget.classList.remove("hidden")
    }
  }
}
