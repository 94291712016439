import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch"
export default class extends Controller {

  static targets = [
    "switchButton", "labelLeft", "labelRight", "containerA", "containerB"
  ]

  connect() {
    this.containerATarget.classList.remove("hidden")
    this.containerBTarget.classList.add("hidden")
  }

  switchToA(){
    this.switchButtonTarget.classList.remove("translate-x-5")
    this.switchButtonTarget.classList.add("translate-x-0")
    this.labelRightTarget.classList.remove("text-zinc-200")
    this.labelRightTarget.classList.add("text-zinc-600")
    this.labelLeftTarget.classList.remove("text-zinc-600")
    this.labelLeftTarget.classList.add("text-zinc-200")
    this.containerATarget.classList.remove("hidden")
    this.containerBTarget.classList.add("hidden")
  }

  switchToB(){
    this.switchButtonTarget.classList.remove("translate-x-0")
    this.switchButtonTarget.classList.add("translate-x-5")
    this.labelLeftTarget.classList.remove("text-zinc-200")
    this.labelLeftTarget.classList.add("text-zinc-600")
    this.labelRightTarget.classList.remove("text-zinc-600")
    this.labelRightTarget.classList.add("text-zinc-200")
    this.containerATarget.classList.add("hidden")
    this.containerBTarget.classList.remove("hidden")
  }

  toggleSwitch() {
    let switchButton = this.switchButtonTarget
    if (switchButton.classList.contains("translate-x-0")) {
      this.switchToB()
    } else {
      this.switchToA()
    }
  }
}
