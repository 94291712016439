export default class {
  static data = {}
  static promises = {}

  static fetchContent(contentUrl, processResponse, timeout = 10000, retries = 5) {
    if (this.data[contentUrl]) {
      return Promise.resolve(this.data[contentUrl]);
    } else if (this.promises[contentUrl]) {
      return this.promises[contentUrl];
    } else {
      return this.fetchWithRetry(contentUrl, processResponse, timeout, retries);
    }
  }

  static delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static fetchWithRetry(contentUrl, processResponse, timeout, retries) {
    let requestPromise = fetch(contentUrl)
      .then(response => {
        if (response.status === 200) {
          let deployedVersion = response.headers.get('X-Mrc-Version')
          if (deployedVersion) {
            window.Blaze.updateVersion(deployedVersion)
          }
          return processResponse(response).then(data => {
            this.data[contentUrl] = data;
            setTimeout(() => {
              delete this.data[contentUrl];
              delete this.promises[contentUrl];
            }, timeout);
            return data;
          });
        } else {
          throw new Error(`Failed to fetch content. Status: ${response.status} ${response.statusText}`);
        }
      })
      .catch(error => {
        if (retries > 0) {
          console.warn(`Fetch failed, retrying... (${retries} retries left)`);
          return this.delay(5000).then(() => this.fetchWithRetry(contentUrl, processResponse, timeout, retries - 1));
        } else {
          console.warn(error.message);
        }
      });
    this.promises[contentUrl] = requestPromise;
    return requestPromise;
  }
}