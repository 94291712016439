import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-increment"
export default class extends Controller {
  static targets = ["input"]

  connect() {
  }

  isNumber() {
    return this.inputTarget.attributes["type"].value === "number"
  }

  getStep() {
    let stepValue = this.inputTarget.attributes["step"].value
    return this.numberOrFloat(stepValue)
  }

  getMin() {
    let minValue = this.inputTarget.attributes["min"].value
    if (minValue && minValue !== "") {
      return this.numberOrFloat(minValue)
    } else {
      return 0
    }
  }

  isBlank() {
    return (this.inputTarget.value === undefined || this.inputTarget.value === "" || isNaN(this.inputTarget.value))
  }

  getValue() {
    if (this.isBlank()) {
      return 0
    }
    return this.numberOrFloat(this.inputTarget.value)
  }

  numberOrFloat(value) {
    if (this.isNumber()) {
      return parseInt(value)
    } else {
      return parseFloat(value)
    }
  }

  modify(increment) {
    let quantityInput = this.inputTarget
    let step = this.getStep()
    let currentValue = this.getValue()
    let newValue = increment ? (currentValue + step) : (currentValue - step)
    let min = this.getMin()
    if (newValue < min) {
      newValue = min
    }
    // console.log(`current=${typeof currentValue} step=${typeof step} newValue=${newValue}`)
    if (this.isNumber()) {
      quantityInput.value = Math.round( newValue * 100) / 100 // Round to 2 decimal places
    } else {
      quantityInput.value = newValue.toFixed(2) // Set decimal precision to 2
    }
    quantityInput.dispatchEvent(new Event('input', { bubbles: true }))
  }

  increment() {
    this.modify(true)
  }

  decrement() {
    this.modify(false)
  }
}
