import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="groups"
export default class extends Controller {
  static values = {
    "element": String
  }

  connect() {
  }

  updateFormParams() {
    let symbol = this.element.querySelector("input[name=\"symbol\"]").value
    let elements = this.element.querySelectorAll("input[type=\"radio\"]:checked")
    let filters = []
    elements.forEach((element) => {
      filters.push(`${element.name}=${element.value}`)
    })
    filters.push(`symbol=${symbol}`)
    let query_str = "?" + filters.join("&")
    let groupList = document.getElementById(this.elementValue);
    let url = groupList.getAttribute("data-url") + query_str
    let currentSrc = groupList.getAttribute("src");
    if (currentSrc === url) {
      return
    }
    groupList.innerHTML = "Loading"
    groupList.setAttribute("src", url)
    window.history.pushState({}, null, query_str);
  }
}
