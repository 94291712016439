import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="swap"
export default class extends Controller {
  static targets = ["a", "b"]

  connect() {
  }

  swap() {
    if (this.aTarget.classList.contains("hidden")) {
      this.aTarget.classList.remove("hidden")
      this.bTarget.classList.add("hidden")
    } else {
      this.aTarget.classList.add("hidden")
      this.bTarget.classList.remove("hidden")
    }
  }

}
