import React, {useState} from 'react';
import {Description, Field, FieldGroup, Fieldset, Label, Legend} from "./catalyst/fieldset";
import {Input} from "./catalyst/input";
import {Select} from "./catalyst/select";
import {Textarea} from "./catalyst/textarea";
import {Text} from "./catalyst/text";
import BotIcon from "../icons/bot_icon";
import ChevronLeftIcon from "../icons/chevron_left_icon";
import {Button} from "./catalyst/button";
import {FetchRequest} from "@rails/request.js";
import {PencilSquareIcon} from "@heroicons/react/24/solid";
import {Dialog, DialogActions} from "./catalyst/dialog";

const RoboTraderEdit = ({showEditDialog = false, robotState = {}, setCurrentMode, setRobotState}) => {
  const [formState, setFormState] = useState({
    id_token: robotState?.id_token || '',
    name: robotState?.name || '',
    open_prompt: robotState?.open_prompt || '',
    risk: robotState?.balances || 'balanced',
    total_risk: robotState?.total_risk || '',
    direction: robotState?.direction || '',
    trade_ttl: robotState?.trade_ttl || '',
    ticker: robotState?.ticker || '',
  });

  const syncChanges = async () => {
    try {
      let requestBody = { robot: formState }
      const request = new FetchRequest('post', '/robotrader/new', {body: JSON.stringify(requestBody)})
      const response = await request.perform()
      if (response.ok) {
        const responseJSON = await response.json;
        if (responseJSON.robot && responseJSON.robot.data) {
          let updatedRobot = { ...responseJSON.robot.data, id_token: responseJSON.robot.id_token};
          setFormState(updatedRobot)
          setRobotState(updatedRobot)
          window.location.reload()
        }
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await syncChanges();
    return true;
  };

  const handleChange = (event) => {
    setFormState({...formState, [event.target.name]: event.target.value});
  };

  const onCloseEdit = () => {
    setCurrentMode("view")
    if (formState.id_token === null || formState.id_token === "") {
      window.history.back();
    }
  }

  return <>
    <Dialog onClose={onCloseEdit} open={showEditDialog}>
      <div className="max-w-lg">
        <Fieldset>
          <Legend className="flex flex-row items-center gap-x-2">
            <BotIcon width="60px" height="60px"/>
            <div className="flex flex-col">
              <span className="text-xl md:text-xl">RoboTrader</span>
              <Text className="font-normal">Customize this robot to your own trading style</Text>
            </div>
            <div className="grow"></div>
          </Legend>
          <FieldGroup>
            <Field>
              <Label>Name</Label>
              <Input name="name" value={formState.name} onChange={handleChange}/>
            </Field>
            <div className="grid grid-cols-3 gap-x-8">
              <Field>
                <Label>Ticker</Label>
                <Input name="ticker" value={formState.ticker} onChange={handleChange}/>
              </Field>
              <Field>
                <Label>Direction</Label>
                <Select name="direction" value={formState.direction} onChange={handleChange}>
                  <option value="bull">Bullish</option>
                  <option value="bear">Bearish</option>
                </Select>
              </Field>
              <Field>
                <Label>Hold Time</Label>
                <Select name="trade_ttl" value={formState.trade_ttl} onChange={handleChange}>
                  <option value="7">1 week</option>
                  <option value="14">2 weeks</option>
                  <option value="21">3 weeks</option>
                  <option value="30">1 month</option>
                </Select>
              </Field>
            </div>

            <Field>
              <Label>Bot Prompt</Label>
              <Description>Describe how your bot should open and close trades</Description>
              <Textarea rows="4" name="open_prompt" value={formState.open_prompt} onChange={handleChange}/>
            </Field>
            <div className="grid grid-cols-2 gap-x-8">
              <Field>
                <Label>Risk Tolerance</Label>
                <Select name="risk" value={formState.risk} onChange={handleChange}>
                  <option value="low_risk">Low Risk (25% stop)</option>
                  <option value="balanced">Balanced (40% stop)</option>
                  <option value="high_risk">High Risk (60% stop)</option>
                </Select>
                <Description>Define how your bot tolerates losing positions</Description>
              </Field>
              <Field>
                <Label>Total Risk Amount ($)</Label>
                <Input name="total_risk" value={formState.total_risk} onChange={handleChange}/>
                <Description>The amount of money the bot will manage</Description>
              </Field>
            </div>
          </FieldGroup>
        </Fieldset>
        <DialogActions>
          <div className="w-full mt-8 flex items-center border-t border-zinc-900 pt-4 gap-x-4">
            {formState.id_token && <div data-controller='htmx button'
                                        hx-post={`/robotrader/${formState.id_token}/delete`}
                                        hx-trigger="click"
                                        hx-on="htmx:afterOnLoad:Turbo.visit(window.location.href)"
                                        className="text-sm cursor-pointer text-rose-400 hover:text-rose-300">Delete Bot</div>}
            <div className="grow"/>
            <Button plain onClick={onCloseEdit}>
              <span className="text-zinc-400">Close</span>
            </Button>
            <Button color="light" onClick={handleSubmit}>Save Changes</Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  </>
};

export default RoboTraderEdit;