import { Controller } from "@hotwired/stimulus";
import Tracker from "../components/tracker";
// Define the controller
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (event) => {
      let targetElement = event.target;
      while (targetElement && targetElement !== this.element) {
        if (targetElement.tagName === 'A') {
          this.trackLinkClick(targetElement, event);
          break;
        }
        targetElement = targetElement.parentNode;
      }
      if (targetElement === this.element && targetElement.tagName === 'A') {
        this.trackLinkClick(targetElement, event);
      }
    });
  }

  trackLinkClick(link, event) {
    event.preventDefault()
    Tracker.track("link_click", {href: link.href})
    if (window.location.host === link.host) {
      Turbo.visit(link.href)
    } else {
      window.location.href = link.href
    }
  }
}
