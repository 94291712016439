import _ from "underscore"
import MrcController from "./mrc_controller"
import MrcCache from "./mrc_cache"
import * as echarts from 'echarts'
import { format } from 'date-fns'

// Connects to data-controller="price-chart"
export default class extends MrcController {
  static targets = ['priceChart']

  connect() {
    this.populateCharts()
  }

  populateCharts(params = {}) {
    let dataUrl = this.element.dataset.dataUrl
    if (!_.isEmpty(params)) {
      const url = new URL(dataUrl, window.location.origin)
      for (const key in params) {
        url.searchParams.set(key, params[key])
      }
      dataUrl = url.href
      this.element.dataset.dataUrl = dataUrl
    }
    this.showLoading()
    MrcCache.fetchContent(dataUrl, response => response.json(), 10000).then((data) => {
      if (data.prices) {
        this.createChart(data)
      }
      this.hideLoading()
    })
  }

  createChart(data) {
    var chart = echarts.init(this.priceChartTarget, 'dark', {renderer: 'svg'});
    var candlestickData = []
    var closeData = []
    for (var i = 0; i < data.prices.close.length; i++) {
      let oclh = [
        data.prices.open[i],
        data.prices.close[i],
        data.prices.low[i],
        data.prices.high[i]
      ]
      // console.log(`timestamp: ${data.prices.timestamp[i]}, open: ${data.prices.open[i]}, close: ${data.prices.close[i]}, low: ${data.prices.low[i]}, high: ${data.prices.high[i]}`)
      candlestickData.push(oclh)
      closeData.push(data.prices.close[i])
    }

    let upColor = '#4bffb5'
    let downColor = '#ff4976'
    let previousClose = data.prices.previous_close || data.prices.close[0];
    let isUp = data.prices.close[data.prices.close.length - 1] >= previousClose
    let labelColor = isUp ? upColor : downColor
    let axisBuffer = 0.01
    let yAxisMin = Math.min(...closeData, previousClose);
    let yAxisMax = Math.max(...closeData, previousClose);
    let yRange = yAxisMax - yAxisMin
    if (yRange > 0) {
      let yAxisBuffer = 0.1;
      yAxisMin -= (yRange * yAxisBuffer)
      yAxisMax += (yRange * yAxisBuffer)
    }
    chart.setOption({
      backgroundColor: '#000',
      title: {
        // text: `${data.symbol} - ${data.name} - ${data.interval}`,
        text: `${data.symbol} - ${data.interval}`,
        left: "5px",
        top: "-5px",
        show: false,
        textStyle: {
          color: '#94CDF0',
          // color: '#FFF',
          fontWeight: 'normal',
          fontSize: 14
        }
      },
      grid: {
        left: -5,
        top: 0,
        right: 60,
        bottom: 20
      },
      tooltip: {
        show: false,
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        type: 'category',
        data: data.prices.timestamp,
        axisLine: {
          lineStyle: {
            color: '#8392A5'
          }
        },
        axisLabel: {
          formatter: function (value) {
            var date = new Date(value * 1000);
            return format(date, 'h:mm a');
          }
        }
      },
      yAxis: {
        scale: true,
        splitNumber: 3,
        axisTick: {
          show: true
        },
        min: yAxisMin,
        max: yAxisMax,
        axisLine: {
          lineStyle: {
            color: '#8392A5'
          }
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#1c1c1c'
          }
        },
        axisPointer: {
          label: {
            color: '#000',
            backgroundColor: '#fff',
            show: true
          }
        },
        position: 'right'
      },
      series: [
        {
          type: 'line',
          data: closeData,
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: isUp ? upColor : downColor,
          },
          areaStyle: {
            color: isUp ? upColor : downColor,
            opacity: 0.25,
            origin: 'start',
          },
          markLine: {
            animation: false,
            symbol: ['none', 'none'],
            emphasis: {
              disabled: true
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              },
              backgroundColor: labelColor,
              color: isUp ? "#000" : '#fff',
              padding: 5,
            },
            data: [
              {
                yAxis: data.prices.close[data.prices.close.length - 1],
                lineStyle: {
                  color: labelColor,
                  width: 0,
                },
              },
              {
                yAxis: previousClose,
                lineStyle: {
                  color: '#666',
                  type: 'dashed',
                  dashOffset: 0,
                },
                label: {
                  show: true,
                  formatter: `Previous Close (${previousClose})`,
                  position: 'start',
                  color: '#aaa',
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  offset: [140, (isUp ? -10 : 10)],
                }
              }
            ]
          },
        }
      ]
    });
  }
}
