import { Controller } from "@hotwired/stimulus";
import { StreamChat } from 'stream-chat';
import React, { useEffect } from "react";
import {
  Chat,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
  useChannelActionContext,
} from 'stream-chat-react';
import { createRoot } from 'react-dom/client';

export default class extends Controller {
  static values = {
    "streamApiKey": String,
    "streamUserToken": String,
    "channelName": String,
    "currentUserId": String,
    "currentUsername": String,
    "channelId": String,
    "channelType": String,
    "channelClearUrl": String,
    "imageUrl": String
  };

  connect() {
    const userId = this.currentUserIdValue;
    const userName = this.currentUsernameValue;
    const user = {
      id: userId,
      name: userName,
      image: `https://getstream.io/random_png/?id=${userId}&name=${userName}`,
    };
    const apiKey = this.streamApiKeyValue;
    const chatClient = new StreamChat(apiKey);
    chatClient.connectUser(user, this.streamUserTokenValue);
    const channel = chatClient.channel(this.channelTypeValue, this.channelIdValue, {
      image: this.imageUrlValue,
      name: this.channelNameValue,
    });

    const ChatComponent = () => {
      const MessageListCustom = () => {
        const { channel, loadingMore } = useChannelStateContext();
        const { markRead, jumpToLatestMessage } = useChannelActionContext();

        useEffect((gsq) => {
          // console.log(`loading_more=${loadingMore} unread=${!channel?.state.unreadCount}`);
          // if (!channel?.state.unreadCount || loadingMore) return;
          if (!loadingMore) {
            markRead();
            this.scrollToBottom();
            channel.markRead();
            jumpToLatestMessage();
          }
        }, [loadingMore]);
        return <MessageList />;
      };

      return (
          <Chat client={chatClient} theme='str-chat__theme-dark'>
            <Channel channel={channel} theme='str-chat__theme-dark'>
              <Window>
                <div className="flex flex-col h-full">
                  <ChannelHeader />
                  <div className="flex-1 overflow-y-auto relative">
                    <MessageListCustom />
                  </div>
                  <div className="flex-shrink-0">
                    <MessageInput closeEmojiPickerOnClick="true"/>
                  </div>
                </div>
              </Window>
              <Thread />
            </Channel>
          </Chat>
      );
    };

    createRoot(this.element).render(<ChatComponent />);
  }

  scrollToBottom() {
    let list = this.element.querySelector(".str-chat__list")
    list.scrollTop = list.scrollHeight;
  }
}