import React from 'react';
import { parseISO, format, differenceInYears, differenceInMonths, differenceInDays } from "date-fns";
import PriceChart from "./price_chart";
import { formatMoney, pctPlus } from "../utils";

const PriceChartComponent = ({ functionCallData }) => {
  const functionCall = functionCallData.function_call;
  const functionCallResponse = functionCallData.function_call_response;
  const dataUrl = functionCallData.urls ? functionCallData.urls.data_url : null;
  const linkUrl = functionCallData.urls?.link_url;
  const startTime = parseISO(functionCall.args.start_time);
  const endTime = functionCall.args.end_time ? parseISO(functionCall.args.end_time) : new Date()

  // Calculate the duration
  const yearsDiff = differenceInYears(endTime, startTime);
  const monthsDiff = differenceInMonths(endTime, startTime);
  const daysDiff = differenceInDays(endTime, startTime);

  // Determine how to display the duration
  let duration;
  if (yearsDiff > 0) {
    duration = `${yearsDiff} year${yearsDiff !== 1 ? 's' : ''}`;
  } else if (monthsDiff > 0) {
    duration = `${monthsDiff} month${monthsDiff !== 1 ? 's' : ''}`;
  } else {
    duration = `${daysDiff} day${daysDiff !== 1 ? 's' : ''}`;
  }

  const imageUrl = functionCallData.urls?.image_url;
  const isPositive = functionCallResponse.change > 0;
  const percentageChange = ((functionCallResponse.change / functionCallResponse.initial_value) * 100).toFixed(1);

  return (
    <div className="border border-zinc-900 rounded-lg mt-4 ml-10 mb-2 max-w-xs flex flex-col">
      <a href={linkUrl} className="block w-full text-xs uppercase text-zinc-500 bg-zinc-900 text-center px-2 py-1 rounded-t-lg">
        <div className="flex flex-row items-center justify-start">
          {imageUrl ?
              <div className="w-fit mr-2">
                <img src={imageUrl} className="border border-zinc-800 w-6 h-6 rounded-full"></img>
              </div> : null}
          <div className="text-base text-dblue-300">{functionCall.args.ticker_symbol}</div>
          <div className="grow"></div>
          <div className="flex flex-row gap-x-2 text-sm items-center">
            <div className="text-zinc-300 font-bold">{formatMoney(functionCallResponse.last_value)}</div>
            <div className={`${isPositive ? "text-green-300" : "text-rose-300"}`}>
              {pctPlus(functionCallResponse.change)}
            </div>
            <span className={`whitespace-nowrap rounded-lg px-2 text-xs/6 font-semibold ${isPositive
              ? 'bg-green-400/10 text-green-400'
              : 'bg-rose-400/10 text-rose-400'}`}>{`${isPositive ? "+" : ""}${percentageChange}%`}</span>
          </div>
        </div>
      </a>
      <div className="text-xs uppercase text-zinc-500 px-2 pb-2 ">{format(startTime, 'MMMM d, yyyy')} - {format(endTime, 'MMMM d, yyyy')} ({duration})</div>
      <div className="pt-4">
        <PriceChart height="140px" dataUrl={dataUrl} minimal={false} />
      </div>
    </div>
  );
};

export default PriceChartComponent;