import { Controller } from "@hotwired/stimulus"
import { html, render } from "lit-html";

// Connects to data-controller="mrc"
export default class extends Controller {
  connect() {
  }

  csrfToken() {
    return document.head.querySelector("meta[name=csrf-token]")?.content
  }

  getMetaValue(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute("content")
  }

  getController(target, identifier) {
    this.application.getControllerForElementAndIdentifier(target, identifier)
  }

  showLoading() {
    if (this.loadingContainer == null) {
      this.loadingContainer = document.createElement("div")
      render(html`<div class="z-20 bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full flex justify-center items-start">
        <div class="p-2 bg-black border border-zinc-800 rounded-lg absolute top-20 flex flex-row items-center gap-x-2">
            <div class="w-5 h-5 spinner"></div>
            <span>Loading</span>
        </div>
      </div>`, this.loadingContainer)
      this.element.prepend(this.loadingContainer)
    }
  }

  hideLoading() {
    if (this.loadingContainer) {
      this.loadingContainer.remove()
      this.loadingContainer = null
    }
  }
}
