import React from 'react';
import {useQuery} from '@tanstack/react-query';
import PositionsList from "./position_list";
import EmbedContainer from "./embed_container";

const PositionListComponent = ({dataUrl}) => {
  const {data, error, isLoading} = useQuery({
    queryKey: [dataUrl],
    queryFn: () =>
      fetch(dataUrl).then((res) =>
        res.json(),
      ),
  });

  if (isLoading) {
    return <EmbedContainer>
      <div className="p-2">Loading...</div>
    </EmbedContainer>;
  }

  if (error) {
    return <EmbedContainer>
      <div>Error: {error.message}</div>
    </EmbedContainer>
  }

  return (
    <div>
      {data.positions && <PositionsList className="ml-10" positions={data.positions}/>}
    </div>
  );
};

export default PositionListComponent;