import RangeController from "./range_controller"
import { ColorType, createChart, CrosshairMode } from "lightweight-charts";
import _ from "underscore";

// Connects to data-controller="flow-chart"
export default class extends RangeController {
  static targets = ['chart']

  connect() {
    this.populateChart()
  }

  _createChart(width) {
    return createChart(this.chartTarget, {
      width: this.chartTarget.scrollWidth,
      height: 150,
      layout: {
        background: { type: ColorType.Solid, color: '#000000' },
        textColor: '#999999',
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        horzLine: {
          // visible: false,
        },
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0)',
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
        timeVisible: true,
      },
    });
  }

  populateChart() {
    fetch(this.element.dataset.dataUrl)
      .then((response) => response.json())
      .then((data) => {
        const flowData = data["flow"]
        if (flowData.length === 0) {
          return;
        }
        // console.log(flowData)
        let calls = _.where(flowData, { option_type: "call"})
        let puts = _.where(flowData, { option_type: "put"})

        function toChartData(call) {
          return {
            time: call["time"],
            value: call["total_notional"]
          }
        }

        let callChartData = _.map(calls, toChartData)
        let putChartData = _.map(puts, toChartData)

        if (this.chart == null) {
          this.chart = this._createChart(this.width)
        }
        if (this.callLineSeries == null) {
          this.callLineSeries = this.chart.addLineSeries({
            color: '#26a69a',
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          })
        }
        this.callLineSeries.setData(callChartData)
        if (this.putLineSeries == null) {
          this.putLineSeries = this.chart.addLineSeries({
            color: '#a6263d',
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          })
        }
        this.putLineSeries.setData(putChartData)
        this.chart.timeScale().fitContent()
      })
  }
}
