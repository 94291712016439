import { Controller } from "@hotwired/stimulus"
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'

// Connects to data-controller="login-sms"
export default class extends Controller {
  static targets = ["phoneInput", "phoneNumber", "submitBtn"]
  connect() {
    this.checkValid()
    this.phoneInputTarget.addEventListener('input', (event) => {
      this.checkValid()
    })
  }

  checkValid() {
    this.submitBtnTarget.classList.add("disabled")
    const value = this.phoneInputTarget.value
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value, 'US')
      if (phoneNumber) {
        this.submitBtnTarget.classList.remove("disabled")
        this.phoneNumberTarget.value = phoneNumber.number
      }
    }
  }
}
