import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.clearNotice()
    }, 5000)
  }

  clearNotice() {
    this.element.remove()
  }
}
