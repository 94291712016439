import React, { memo } from "react";
import DownArrowIcon from "../icons/down_arrow_icon";
import UpArrowIcon from "../icons/up_arrow_icon";
import PriceChart from "./price_chart";
import { formatMoney, pctPlus } from "../utils";

const QuoteComponent = ({functionCallData}) => {
  const quote = functionCallData.function_call_response;
  const linkUrl = functionCallData.urls ? functionCallData.urls.link_url : null;
  const imageUrl = functionCallData.urls ? functionCallData.urls.image_url : null;
  const dataUrl = functionCallData.urls ? functionCallData.urls.data_url : null;
  const color = quote.change > 0 ? 'green' : 'red';

  return (
    <div className="ml-10 my-4 flex flex-col w-fit border border-zinc-900 hover:border-zinc-700 rounded-lg">
      {quote.name ? <span className="text-xs uppercase text-zinc-500 bg-zinc-900 text-center px-2 py-1 rounded-t-lg">{quote.name}</span> : null }
      <a href={linkUrl}
         className="items-center flex flex-row w-fit px-3 mt-2 mb-2">
        {
          imageUrl ?
            <div className="w-fit mr-3">
            <span href={linkUrl}>
              <img src={imageUrl} className="border border-zinc-800 w-10 h-10 rounded-full"></img>
            </span>
            </div> : null
        }
        <div className="flex flex-col">
          <span className="text-dblue-300 text-lg" href={linkUrl}>{quote.symbol}</span>
          <span className="text-xl text-zinc-300 font-normal -mt-1">{formatMoney(quote.latestPrice.toFixed(2))}</span>
        </div>
        <div className="pl-2"> {quote.change > 0 ? <UpArrowIcon/> : <DownArrowIcon/>}</div>
        <div className={`flex flex-col text-${color}-500 -mt-1`}>
          <span className="text-base text-right">{pctPlus(quote.change)}</span>
          <span className="text-center">({pctPlus(quote.changePercent)}%)</span>
        </div>
      </a>
      {dataUrl ? <PriceChart dataUrl={dataUrl}/> : null}
    </div>
  );
};

export default memo(QuoteComponent);