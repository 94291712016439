import Controller from "./mrc_controller"
import MrcEvent from "./mrc_event"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="option-leg-row"
export default class extends Controller {
  static targets = [
    'orderSide',
    'quantity',
    'expiration',
    'strike',
    'optionType',
    'bid', 'ask', 'last', 'delta', 'iv',
    'removeLegBtn'
  ]

  expirationChanged(event) {
    let symbol = this.element.dataset.symbol
    let lastStrike = this.strikeTarget.value
    let params = {
      symbol: symbol,
      expiration_date: this.expirationTarget.value
    }
    const request = new FetchRequest("post", this.element.dataset.optionStrikesUrl, {
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    request.perform()
      .then(async (response) => {
        let json = await response.json
        if (!json.success || !json.strikes || json.strikes.length < 1) {
          return
        }

        let strikes = json.strikes
        this.strikeTarget.innerHTML = ""
        let defaultStrike = this.element.dataset.defaultStrike
        let selectedStrike = lastStrike
        if (!selectedStrike || !strikes.includes(parseFloat(lastStrike))) {
          selectedStrike = defaultStrike
        }
        strikes.forEach((strike) => {
          let optionElement = document.createElement("option")
          optionElement.value = strike
          optionElement.innerHTML = strike
          this.strikeTarget.append(optionElement)
          if (selectedStrike && parseFloat(strike) === parseFloat(selectedStrike)) {
            optionElement.setAttribute("selected", "true")
          }
        })
        this.fetchAndUpdateQuote(true)
      })
  }

  connect() {
    // Show the remove button unless we're the first leg in the trade
    // let index = Array.from(this.element.parentNode.children).indexOf(this.element)
    // if (index > 0) {
    //   this.removeLegBtnTarget.classList.remove("hidden")
    // } else {
    //   this.removeLegBtnTarget.classList.add("hidden")
    // }

    this.fetchAndUpdateQuote(false)
    setTimeout(() => {
      this.fetchAndUpdateQuote(false)
    }, 30)
  }

  getExpirationValue() {
    if (this.expiration) {
      return this.expirationTarget.value
    }
    return null
  }

  getOrderSide() {
    if (this.orderSideTarget) {
      return this.orderSideTarget.value
    }
    return null
  }

  setQuantity(newQuantity) {
    if (this.quantityTarget) {
      this.quantityTarget.value = newQuantity
    }
  }

  getQuantity() {
    if (this.quantityTarget) {
      return parseInt(this.quantityTarget.value)
    }
    return null
  }

  getLastQuote() {
    return this.lastQuote
  }

  reverseLeg() {
    let currentOrderSide = this.getOrderSide()
    if (currentOrderSide.includes("buy")) {
      currentOrderSide = currentOrderSide.replace("buy", "sell")
    } else {
      currentOrderSide = currentOrderSide.replace("sell", "buy")
    }
    if (currentOrderSide.includes("close")) {
      currentOrderSide = currentOrderSide.replace("close", "open")
    } else {
      currentOrderSide = currentOrderSide.replace("open", "close")
    }
    if (this.orderSideTarget) {
      this.orderSideTarget.value = currentOrderSide
    }
  }

  removeLeg() {
    let count = Array.from(this.element.parentNode.children).length
    if (count > 1) {
      this.element.outerHTML = ""
    } else {
      this.element.querySelectorAll("select").forEach((elem) => {
        elem.selectedIndex = null
      })
      // alert("Only row")
    }

    document.dispatchEvent(new Event(MrcEvent.OPTION_LEG_CHANGED))
  }

  fetchAndUpdateQuote(orderLegChanged) {
    this.setOrderStyle()

    let optionType = this.optionTypeTarget.value
    let expiration = this.expirationTarget.value
    let strike = this.strikeTarget.value
    let symbol = this.element.dataset.symbol

    if (optionType !== undefined
      && expiration !== undefined
      && strike !== undefined
      && symbol !== undefined
      && symbol !== "") {
      const params = {
        symbol: symbol,
        strike: strike,
        expiration: expiration,
        option_type: optionType
      }


      const request = new FetchRequest("post", this.element.dataset.optionQuoteUrl, {
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      request.perform()
        .then(async (response) => {
          let json = await response.json
          if (!json.success) {
            return
          }
          this.lastQuote = json
          this.bidTarget.innerHTML = "0"
          this.askTarget.innerHTML = "0"
          if (json.option.last !== undefined) {
            this.lastTarget.innerHTML = json.option.last
          }
          if (json.option.bid) {
            this.bidTarget.innerHTML = json.option.bid
          }
          if (json.option.ask) {
            this.askTarget.innerHTML = json.option.ask
          }
          if (orderLegChanged) {
            document.dispatchEvent(new CustomEvent(MrcEvent.OPTION_LEG_CHANGED))
          } else {
            document.dispatchEvent(new CustomEvent(MrcEvent.ORDER_QUOTE_CHANGED))
          }

          if (json.option.greeks) {
            if (json.option.greeks.delta) {
              this.deltaTarget.innerHTML = json.option.greeks.delta.toFixed(2)
            }
            let impliedVol = json.option.greeks.implied_volatility || json.option.greeks.mid_iv
            if (impliedVol) {
              this.ivTarget.innerHTML = `${(impliedVol * 100).toFixed(1)}`
            }
          }
        })
    }
  }

  setOrderStyle() {
    let orderSide = this.getOrderSide()
    if (orderSide) {
      if (orderSide.includes("buy")) {
        this.orderSideTarget.classList.remove("border-rose-500")
        this.orderSideTarget.classList.add("border-green-500")
      } else {
        this.orderSideTarget.classList.remove("border-green-500")
        this.orderSideTarget.classList.add("border-rose-500")
      }
    }
  }
}
