import {Controller} from "@hotwired/stimulus"
import sparkline from "@fnando/sparkline"
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz'
import {parse} from "date-fns"
import MrcCache from './mrc_cache'

// Connects to data-controller="ticker"
export default class extends Controller {

  connect() {
    this.updateSparkline()
    this.refreshInterval = setInterval(() => {
      this.updateSparkline()
    }, 60000)
  }

  disconnect() {
    clearInterval(this.refreshInterval)
  }

  updateSparkline() {
    let width = this.element.offsetWidth
    let marketProgress = Math.max(this.getMarketProgress(), 0.5)
    width *= marketProgress
    let sparkline_element = this.element.querySelector(".sparkline")
    sparkline_element.setAttribute("width", width)
    let ticker = this.element.dataset.ticker

    MrcCache.fetchContent(`/data/intraday?ticker=${ticker}`, response => response.json(), 45000).then((data) => {
      let chart_data = []
      let max = 0
      let min = Number.MAX_SAFE_INTEGER
      data.closes.forEach((x, index) => {
        chart_data.push(x)
        if (x > max) {
          max = x
        }
        if (x < min) {
          min = x
        }
      })
      // add buffer for the chart
      // max = max * 1.5
      min = min * 0.997
      // normalize data?
      chart_data = chart_data.map(x => (x - min) / (max - min))
      // console.log(chart_data)
      // console.log(data.closes.length)
      sparkline(sparkline_element, chart_data)
    })
  }

  getMarketProgress() {
    let currentProgress = 1
    let timeZone = "America/New_York"
    let closeTime = utcToZonedTime(parse("4:00 PM", "h:mm aaa", new Date()), timeZone)
    let openTime = utcToZonedTime(parse("9:30 AM", "h:mm aaa", new Date()), timeZone)
    const zonedTime = utcToZonedTime(new Date(), timeZone)
    var dayOfWeek = zonedTime.getDay()
    var isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)
    if (zonedTime > openTime && zonedTime < closeTime && zonedTime && !isWeekend) {
      let totalTime = (closeTime.getTime() - openTime.getTime())
      let elapsedSeconds = (zonedTime.getTime() - openTime.getTime())
      currentProgress =  elapsedSeconds / totalTime
    }
    return currentProgress
  }
}
