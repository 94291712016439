import React, {useEffect, useState} from 'react';
import RoboTraderEdit from "./robotrader_edit";
import OrderList from "./order_list";
import RoboTraderView from "./robotrader_view";
import {Dialog} from "./catalyst/dialog";

const RoboTraderComponent = ({data, mode = 'view'}) => {
  const [currentMode, setCurrentMode] = useState(mode);
  const [robotState, setRobotState] = useState(null);

  useEffect(() => {
    let robot = data.robot
    if (robot && robot.data) {
      if (robot.data && robot.id_token) {
        setRobotState({ ...robot.data, id_token: robot.id_token})
      }
    }
  }, [data]);

  const onCloseEdit = () => {
    setCurrentMode("view")
  }
  let orders = data.orders || []
  let showEditDialog = currentMode === "edit" || (robotState === null);
  return (
    <div className="p-4 md:px-8 max-w-lg">
      { showEditDialog && <RoboTraderEdit showEditDialog={showEditDialog} setRobotState={setRobotState} robotState={robotState} setCurrentMode={setCurrentMode}/>}
      { robotState && <RoboTraderView robotState={robotState} data={data} setCurrentMode={setCurrentMode} />}
    </div>
  );
};

export default RoboTraderComponent;