import { Controller } from "@hotwired/stimulus"
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { parse, formatDuration, intervalToDuration } from 'date-fns'

// Connects to data-controller="market-time"
export default class extends Controller {
  static targets = ["time", "icon", "status", "timeleft"]
  static values = {"isOpen": Boolean}

  updateTime() {
    let currentTime = new Date();
    let closeTime = parse("4:00 PM", "h:mm aaa", new Date())
    let openTime = parse("9:30 AM", "h:mm aaa", new Date())

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const currentTimeUtc = zonedTimeToUtc(currentTime, timeZone)
    const openTimeUtc = zonedTimeToUtc(openTime, 'America/New_York')
    const closeTimeUtc = zonedTimeToUtc(closeTime, 'America/New_York')
    const currentTimeString = formatInTimeZone(currentTime, 'America/New_York', 'h:mm aaa zzz').toUpperCase()
    this.timeTarget.innerHTML = currentTimeString
    const isSunday = currentTimeUtc.getDay() === 0
    // TODO(kiran): handle after hours trading
    // const isAfterHours = isSunday && currentTimeUtc > closeTimeUtc;
    let afterClose = currentTimeUtc >= closeTimeUtc
    let beforeOpen = currentTimeUtc <= openTimeUtc
    if (afterClose || beforeOpen || isSunday || !this.isOpenValue) {
      this.statusTarget.innerHTML = "Market Closed"
      this.iconTarget.classList.add("hidden")
      this.timeleftTarget.classList.remove("text-zinc-400")
      this.timeleftTarget.classList.add("text-zinc-400")
      // this.timeleftTarget.innerHTML = `Market opens in ${formatDistanceStrict(currentTimeUtc, openTimeUtc)}`
      this.timeleftTarget.innerHTML = ""
    } else {
      this.statusTarget.innerHTML = "Market Open"
      this.iconTarget.classList.remove("hidden")
      this.timeleftTarget.classList.remove("text-dgreen-400")
      this.timeleftTarget.classList.add("text-dgreen-400")
      let duration = intervalToDuration({
        start: currentTimeUtc,
        end: closeTimeUtc
      });
      let durationString = formatDuration(duration, {format: ['hours', 'minutes']});
      this.timeleftTarget.innerHTML = `Market closes in ${durationString}`
    }
  }

  disconnect() {
    clearInterval(this.refreshInterval)
  }

  connect() {
    this.updateTime()
    this.refreshInterval = setInterval(() => {
      this.updateTime()
    }, 2000)
  }
}
