import {Controller} from "@hotwired/stimulus"
import MrcEvent from "./mrc_event";

// Connects to data-controller="price-type-select"
export default class extends Controller {
  static targets = ['priceValue', 'estimatedTotalCost', 'creditOrDebit', 'priceTypeSelect']

  defaultOptionsForOrderType() {
    return {
      "stock": [
        { name: "Market", value: "market", selected: true },
        // { name: "Market on close", value: "market_close", selected: false },
        { name: "Limit", value: "limit", selected: false },
        // { name: "Stop on quote", value: "stop_quote", selected: false },
        // { name: "Stop limit on quote", value: "stop_limit_quote", selected: false },
        // { name: "Trailing Stop $", value: "stop_limit_quote", selected: false },
        // { name: "Trailing Stop %", value: "stop_limit_quote", selected: false },
      ],
      "option": [
        { name: "Market", value: "market", selected: false },
        { name: "Limit", value: "limit", selected: true },
        // { name: "Stop on quote", value: "stop_quote", selected: false },
        // { name: "Stop Limit on quote", value: "stop_limit_quote", selected: false },
        // { name: "Trailing Stop", value: "stop_trailing", selected: false },
      ],
      "multileg": [
        { name: "Market", value: "market", selected: false },
        { name: "Net Debit", value: "debit", selected: true },
        { name: "Net Credit", value: "credit", selected: false },
        { name: "Even", value: "even", selected: false },
      ],
    }
  }

  connect() {
    document.addEventListener(MrcEvent.SET_PRICE, (event) => {
      this.setEstimatedPrice(event.detail)
    })
    document.addEventListener(MrcEvent.ORDER_FORM_SELECTED, (event) => {
      this.setDefaultSelectOptions(event.detail.order_type)
    })
    this.setDefaultSelectOptions("option") // TODO(kiran): hardcoded default
  }

  setEstimatedPrice(priceDetails) {
    let inputElement = this.priceValueTarget.querySelector("input")
    let price = priceDetails.price;
    let shouldWrite = inputElement.value === "" || priceDetails.override
    // console.log(`setEstimatedPrice overide=${priceDetails.override} shouldWrite=${shouldWrite} huh=${inputElement.value < 0} value=${inputElement.value}`)
    if (price && shouldWrite) {
      inputElement.value = Math.abs(price)
      inputElement.innerHTML = Math.abs(price)
      this.updateEstimatedCost();

      if (this.orderType === "multileg") {
        if (price > 0) {
          this.setSelectedPriceType("debit")
        } else if (price < 0) {
          this.setSelectedPriceType("credit")
        } else {
          this.setSelectedPriceType("even")
        }
      } else {
        this.setSelectedPriceType("limit")
      }
    }
  }

  updateEstimatedCost() {
    let price = this.priceValueTarget.querySelector("input").value
    let estimatedCost = this.getQuantity() * price * 100
    if (estimatedCost) {
      let moneyString = Math.abs(estimatedCost).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      this.estimatedTotalCostTarget.innerHTML = moneyString
      let optionLegs = this.optionFormController().getOptionLegControllers()
      if (optionLegs.length === 1) {
        let creditOrDebit = optionLegs[0].getOrderSide().includes("sell") ? "credit" : "debit"
        this.creditOrDebitTarget.innerHTML = creditOrDebit
      } else {
        let creditOrDebit = estimatedCost < 0 ? "credit" : "debit"
        this.creditOrDebitTarget.innerHTML = creditOrDebit
      }
    }
  }

  getPrice() {
    let price = this.priceValueTarget.querySelector("input").value
    if (this.priceTypeSelectTarget.value === "credit") {
      price = -price
    }
    return price
  }

  getQuantity() {
    let optionFormController = this.optionFormController();
    return optionFormController.getQuantity()
  }

  optionFormController() {
    // there are 2 option-form controllers when entering a new trade because doing things in stimulus is terrible and confusing
    let optionFormElements = document.getElementById("option_form").querySelectorAll("div[data-controller=\"option-form\"]")
    let option_form_element
    if (optionFormElements.length > 1) {
      option_form_element = optionFormElements[1]
    } else {
      option_form_element = optionFormElements[0]
    }
    let optionFormController = this.application.getControllerForElementAndIdentifier(option_form_element, 'option-form')
    return optionFormController;
  }

  updatePrice() {
    let price = this.getPrice()
    let quantity = this.getQuantity()
    document.dispatchEvent(new CustomEvent(MrcEvent.SET_PRICE, {
      detail: {
        price: price,
        quantity: quantity
      }
    }))
  }

  setDefaultSelectOptions(orderType, selectedOptionValue = undefined) {
    this.priceTypeSelectTarget.innerHTML = ""
    this.orderType = orderType
    let defaultOptions = this.defaultOptionsForOrderType()[orderType];
    defaultOptions.forEach((option) => {
      let optionElement = document.createElement("option")
      optionElement.value = option.value
      optionElement.innerHTML = option.name
      this.priceTypeSelectTarget.append(optionElement)
      if (option.selected) {
        selectedOptionValue = option.value
        optionElement.setAttribute("selected", "true")
      }
    })

    this.setSelectedPriceType(selectedOptionValue)
  }

  // Let new elements be added before setting select
  setSelectedPriceType(selectedOptionValue) {
    if (selectedOptionValue) {
      Promise.resolve().then(() => {
        this.priceTypeSelectTarget.value = selectedOptionValue
        this.setLimitFormVisibility(this.shouldShowLimitForm(selectedOptionValue))
      })
    }
  }

  shouldShowLimitForm(price_type) {
    if (['limit', 'debit', 'credit'].includes(price_type)) {
      return true
    } else {
      return false
    }
  }

  priceTypeSelected(event) {
    this.setLimitFormVisibility(this.shouldShowLimitForm(event.target.value))
    this.updatePrice()
  }

  setLimitFormVisibility(visible) {
    if (visible) {
      this.priceValueTarget.classList.remove("hidden")
    } else {
      this.priceValueTarget.classList.add("hidden")
    }
  }
}
