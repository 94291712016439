import QuoteComponent from "./quote";
import React from "react";
import AccountBalances from "./account_balances";
import PositionList from "./position_list";
import PriceChartComponent from "./price_chart_component";
import OrderList from "./order_list";
import Order from "./order";
import PositionListComponent from "./position_list_component";
import EmbedContainer from "./embed_container";
import BotIcon from "../icons/bot_icon";
import {Button} from "./catalyst/button";

const FunctionCallComponent = ({functionCallData}) => {
  let functionCall = functionCallData.function_call
  let functionCallResponse = functionCallData.function_call_response
  switch (functionCall.name) {
    case "quote_api_quote":
      return (
        <QuoteComponent functionCallData={functionCallData}/>
      );
    case "quote_api_price_chart":
      return (
        <PriceChartComponent functionCallData={functionCallData}/>
      );
    case "trading_api_balances":
      return (
        <AccountBalances className="ml-10 max-w-xs" accountData={functionCallData.function_call_response}/>
      )
    case "trading_api_place_order":
      const order = functionCallData.function_call.args
      order.transaction_date = new Date()
      order.quantity = order.quantity || order["legs"][0].quantity
      order.side = order.side || order["legs"][0].side
      order.status = "placed"
      order.descriptions = order.descriptions || functionCallData.function_call_response.descriptions
      return (
        <div className="border border-zinc-900 rounded-lg mt-4 ml-10 mb-2 max-w-xs flex flex-col overflow-hidden">
          <span className="block w-full text-xs uppercase text-zinc-500 bg-zinc-900 text-center px-2 py-1 rounded-t-lg">
            Order Placed
          </span>
          <Order order={order} key={order.id || Math.random()}/>
        </div>
      )
    case "trading_api_order_history":
      const orders = functionCallData.function_call_response.orders
      return (
        <OrderList orders={orders} className="max-w-[400px] ml-10"/>
      );
    case "trading_api_positions":
      return (
        functionCallResponse.data_url && <PositionListComponent dataUrl={functionCallResponse.data_url}/>
      );
    case "manage_trade":
      let robot = functionCallResponse.robot;
      return (robot && <EmbedContainer className="ml-12 p-2 px-4">
          <div className="flex flex-col items-center gap-y-2 mb-2">
            <div className="flex flex-row items-center gap-x-2">
              <BotIcon />
              <span className="font-semibold text-lg">{robot.data?.name || "Robot"} created</span>
            </div>
            <a href={`/robotrader/${robot.id_token}`} data-turbo-action="advance">
              <Button color="cyan">Manage Robot</Button>
            </a>
          </div>
        </EmbedContainer>
      );
    default:
      return (
        <div className=" flex flex-col">
          <div>{JSON.stringify(functionCallData)}</div>
        </div>
      );
  }
};

export default FunctionCallComponent;