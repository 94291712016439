import React, {useEffect, useState} from 'react'

import FunctionCallComponent from "../components/function_call";
import {
  Channel,
  Chat,
  Message,
  MessageInput,
  MessageList,
  Thread,
  useChannelActionContext,
  useChannelStateContext,
  useMessageContext,
  Window,
} from 'stream-chat-react';
import {Switch} from "./catalyst/switch";
import {ArrowPathIcon, SpeakerWaveIcon} from '@heroicons/react/24/solid'
import {SpeakerWaveIcon as SpeakerWaveOutlineIcon} from '@heroicons/react/24/outline'
import {QueryClient, useQuery} from "@tanstack/react-query";
import EmbedContainer from "./embed_container";
import {StreamChat} from "stream-chat";

const queryClient = new QueryClient()

const AgentChatComponent = ({dataUrl}) => {
  const [voiceChatEnabled, setVoiceChatEnabled] = useState(false);
  const [channel, setChannel] = useState(null);
  const [chatDetails, setChatDetails] = useState(null);
  const [chatClient, setChatClient] = useState(null);

  const {data, error, isLoading} = useQuery({
    queryKey: [dataUrl],
    queryFn: () =>
      fetch(dataUrl).then((res) =>
        res.json(),
      ),
  });

  useEffect(() => {
    if (data) {
      const user = {
        id: data.current_user_id,
        name: data.current_username,
        image: data.current_user_image_url,
      };
      const apiKey = data.stream_api_key;
      const chatClient = new StreamChat(apiKey);
      chatClient.connectUser(user, data.stream_user_token);
      const channel = chatClient.channel(data.channel_type, data.channel_id, {
        image: data.channel_image_url,
        name: data.channel_name,
      });

      const chatDetails = {
        current_user: user,
        channel: {
          id: data.channel_id,
          type: data.channel_type,
          name: data.channel_name,
          imageUrl: data.channel_image_url,
          clearUrl: data.channel_clear_url,
        },
      }
      setChatDetails(chatDetails)
      setChatClient(chatClient)
      setChannel(channel)
    }
  }, [data]);

  const setVoiceChat = (enabled) => {
    setVoiceChatEnabled(enabled)
  }

  if (isLoading) {
    return <EmbedContainer>
      <div className="p-2">Loading...</div>
    </EmbedContainer>;
  }

  if (error) {
    return <EmbedContainer>
      <div>Error: {error.message}</div>
    </EmbedContainer>
  }

  return channel && chatClient && (
    <Chat client={chatClient} theme='str-chat__theme-dark'>
      <Channel channel={channel} theme='str-chat__theme-dark'>
        <Window>
          <div className="flex flex-col h-full">
            <ChannelHeaderCustom channelDetails={chatDetails.channel} voiceChatEnabled={voiceChatEnabled} setVoiceChatEnabled={setVoiceChat}/>
            <div className="flex-1 overflow-y-auto relative">
              <MessageListCustom voiceChatEnabled={voiceChatEnabled}/>
            </div>
            <div className="flex-shrink-0">
              <MessageInput closeEmojiPickerOnClick="true"/>
            </div>
          </div>
        </Window>
        <Thread/>
      </Channel>
    </Chat>
  );
};
const MessageListCustom = ({voiceChatEnabled}) => {
  const {channel, loadingMore, messages} = useChannelStateContext();
  const {markRead, jumpToLatestMessage} = useChannelActionContext();

  useEffect(() => {
    if (!loadingMore) {
      jumpToLatestMessage();
      markRead();
      channel.markRead();
    }
  }, [loadingMore]);

  useEffect(() => {
    const speak = (text) => {
      var utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "en-US"; // language
      utterance.rate = 1; // speed
      utterance.pitch = 1; // pitch
      utterance.volume = 1; // volume
      window.speechSynthesis.speak(utterance);
    }
    const handleMessage = (newMessages) => {
      // console.log('New message received:', newMessages);
      let messageText = newMessages.message.text;
      if (messageText && (newMessages.user.id === "mister-market" || newMessages.user.id === "miss-market")) {
        // console.log(`${messageText} vc=${voiceChatEnabled}`)
        if (!messageText.includes("..") && voiceChatEnabled) {
          speak(messageText)
        }
      }
      setTimeout(() => {
        jumpToLatestMessage();
        markRead();
        channel.markRead();
      }, 1000)
    };
    channel.on('message.new', handleMessage);
    channel.on('message.updated', handleMessage);
    return () => {
      channel.off('message.new', handleMessage);
      channel.off('message.updated', handleMessage);
    };
  }, [channel, voiceChatEnabled]);

  const CustomMessage = () => {
    const {message} = useMessageContext();
    if (message.function_call_data) {
      return (
        <FunctionCallComponent functionCallData={message.function_call_data}/>
      );
    } else {
      return (
        <Message message={message}/>
      );
    }
  };

  return (
    <MessageList Message={CustomMessage}/>
  );
};

const ChannelHeaderCustom = ({setVoiceChatEnabled, voiceChatEnabled, channelDetails}) => {
  const handleVoiceChatToggle = () => {
    // console.log(`setting !voiceChatEnabled = ${!voiceChatEnabled}`)
    setVoiceChatEnabled(!voiceChatEnabled)
  }

  return <div className='shadow-md shadow-black flex flex-col'>
    <div className="bg-zinc-900 border-b border-zinc-800 p-2 flex flex-row items-center gap-x-3">
      <div className='w-10 h-10 overflow-hidden rounded-full'>
        <img className='w-full h-full object-cover' src={channelDetails.imageUrl} alt="channel icon"/>
      </div>
      <div className='flex flex-col font-medium'>
        <div className='text-lg font-medium'>{channelDetails.name}</div>
        <div className="flex flex-row items-center gap-x-1">
          <span className="relative flex h-2 w-2"
                aria-label="User is online"
                title="User is online">
            <span className="animate-[ping_2s_ease-in-out_infinite] absolute inline-flex h-full w-full rounded-full bg-dgreen-500 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-dgreen-500"></span>
          </span>
          <span className="text-xs text-dgreen-500">online</span>
        </div>
      </div>
      <div className='grow'></div>
      <div className='flex flex-row divide-x gap-x-2 divide-zinc-700'>
        <div className="rounded-lg px-2 cursor-pointer flex flex-col items-center gap-y-2">
          {voiceChatEnabled ? <SpeakerWaveIcon className="w-5 h-5"/> : <SpeakerWaveOutlineIcon className="w-5 h-5"/>}
          <Switch name="enable_voice"
                  checked={voiceChatEnabled}
                  onChange={handleVoiceChatToggle}/>
        </div>
        <div className="pl-4 px-2 cursor-pointer flex flex-col items-center gap-y-2"
             data-controller='htmx button'
             hx-post={channelDetails.clearUrl}
             hx-trigger="click"
             hx-on="htmx:afterOnLoad:window.location.reload()">
          <ArrowPathIcon className="w-5 h-5"/>
          <div className='mt-1 uppercase font-semibold text-xs text-zinc-400 hover:text-dgreen-300'>
            RESET
          </div>
        </div>
      </div>
    </div>
  </div>;
}


export default AgentChatComponent;