import mixpanel from "mixpanel-browser"
class Tracker {
  static mixpanelInitialized = false;
  static track(event_name, properties = {}) {
    if (!this.mixpanelInitialized) {
      mixpanel.init("bce554817ca3f8b9f892043fe8820d6d")
      let distinctId = this.getMetaValue("distinct-id")
      if (distinctId && distinctId !== "") {
        mixpanel.identify(distinctId)
        // console.log(distinctId)
      }
      this.mixpanelInitialized = true
    }
    // console.log(`track ${event_name}`)
    // console.log(properties)
    mixpanel.track(event_name, properties)
  }

  static getMetaValue(name) {
    const element = document.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}

export default Tracker