import React from 'react';
import clsx from "clsx";

const EmbedContainer = ({ children, className }) => {
  return (
    <div className={clsx(className, "my-4 flex flex-col w-fit border border-zinc-900 hover:border-zinc-700 rounded-lg")}>
      {children}
    </div>
  );
}

export default EmbedContainer