import { Controller } from "@hotwired/stimulus"
import { autocomplete } from '@algolia/autocomplete-js'
import hotkeys from 'hotkeys-js'

export default class extends Controller {
  static targets = ["autocomplete"]

  disconnect() {
    this.autocomplete.destroy()
  }

  connect() {
    window.TICKER_CACHE = []
    fetch("/listed-tickers.json", {
      headers: {
        'Accept-Encoding': 'gzip'
      },
    }).then((response) => response.json())
      .then((data) => {
        window.TICKER_CACHE = data
      })
    this.autocomplete = autocomplete({
      container: this.autocompleteTarget,
      autoFocus: true,
      defaultActiveItemId: 0,
      placeholder: 'Search',
      getSources() {
        let templates = {
          item({item, html}) {
            return html`
                <div class="cursor-pointer w-full text-base flex flex-row gap-4"
                     href="/market/ticker/${item.symbol}">
                    <div style="width: 40px; height: 40px;" class="flex-none">
                        <img class="w-full h-full" src="/data-img/symbol/${item.symbol}"/>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-white text-lg">${item.symbol}</div>
                        <div class="text-zinc-400">${item.name}</div>
                    </div>
                    </a>`
          },
        }
        return [
          {
            sourceId: 'tickers',
            getItems({query}) {
              let searchQuery = query.toUpperCase()
              let data = window.TICKER_CACHE.map((x) => x)
              data = data.filter(ticker => {
                let symbol = ticker.symbol.toUpperCase()
                let name = ticker.name ? ticker.name.toUpperCase() : "-"
                return symbol.includes(searchQuery.toUpperCase()) || name.includes(searchQuery.toUpperCase())
              })
              let results = data.map(ticker => {
                ticker.match = ticker.symbol.toUpperCase().indexOf(searchQuery)
                if (ticker.match < 0) {
                  let tickerName = ticker.name ? ticker.name.toUpperCase() : "-"
                  ticker.match = tickerName.indexOf(searchQuery)
                }

                if (searchQuery === ticker.symbol.toUpperCase().substring(0, searchQuery.length)) {
                  ticker.match = -5000
                }
                if (ticker.symbol.toUpperCase() === searchQuery) {
                  ticker.match = -10000
                }
                return ticker
              })
              results = results.sort((a, b) => {
                if (a.match < b.match) return -1
                if (a.match > b.match) return 1
                return 0
              })
              return results.slice(0, 10)
            },
            getItemUrl({item}) {
              return item.url;
            },
            onSelect({setIsOpen, item, itemUrl, source, state}) {
              document.querySelector("#main").src = itemUrl
              setIsOpen(false)
            },
            templates: templates,
          }
        ];
      },
    });
  }
}
