import {Controller} from "@hotwired/stimulus"
import { ColorType, createChart, CrosshairMode } from 'lightweight-charts'
import _ from 'underscore'

// Connects to data-controller="option-trades-chart"
export default class extends Controller {
  static targets = ['chart']

  connect() {
    this.width = this.element.scrollWidth
    this.chart = this._createChart(this.width)
    this.populateChart()
  }

  _createChart(width) {
    return createChart(this.chartTarget, {
      width: width,
      height: 300,
      layout: {
        background: { type: ColorType.Solid, color: '#000000' },
        textColor: '#999999',
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        horzLine: {
          labelBackgroundColor: "#48e8cc",
        },
        vertLine: {
          labelBackgroundColor: "#48e8cc",
        },
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0)',
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
        timeVisible: true,
        tickMarkFormatter: function(timestamp) {
          let date = new Date(timestamp * 1000); // assuming the timestamp is in UNIX format
          let time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          return `${date.getMonth() + 1}/${date.getDate()} ${time}`
        },
      },
      localization: {
        timeFormatter: function(timestamp) {
          let date = new Date(timestamp * 1000); // assuming the timestamp is in UNIX format
          let time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          return `${date.getMonth() + 1}/${date.getDate()} ${time}`
        },
      }
    });
  }

  populateChart() {
    fetch(this.element.dataset.dataUrl)
      .then((response) => response.json())
      .then((data) => {
        const trades = data["trades"]
        let groupedByTimestamp = _.groupBy(trades, (trade) => {
          return Math.round(trade["sip_timestamp"] / 1_000_000_000)
        })
        let priceChartData = []
        let sizeChartData = []
        _.each(Object.keys(groupedByTimestamp), (timestamp) => {
          let price = _.first(groupedByTimestamp[timestamp])["price"];
          let size = groupedByTimestamp[timestamp].reduce((accumulator, trade) => {
            return accumulator + trade["size"]
          }, 0)
          priceChartData.push({
            time: parseInt(timestamp),
            value: price,
          })
          sizeChartData.push({
            time: parseInt(timestamp),
            value: size,
          })
        })
        if (this.volumeSeries == null) {
          this.volumeSeries = this.chart.addHistogramSeries({
            color: '#ABC9C6',
            priceFormat: {
              type: 'volume',
            },
            priceScaleId: '',
            lastValueVisible: false,
            priceLineVisible: false,
          })
        }
        this.volumeSeries.setData(sizeChartData)
        this.volumeSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.7, // highest point of the series will be 70% away from the top
            bottom: 0,
          },
        })
        if (this.lineSeries == null) {
          this.lineSeries = this.chart.addLineSeries({
            color: '#26a69a',
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: true,
          })
        }
        this.lineSeries.setData(priceChartData)
        this.chart.timeScale().fitContent()
      })
  }
}
