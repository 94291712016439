window.Blaze = class Blaze {
  static getVersion() {
    const metaElement = document.querySelector('meta[name="mrc-version"]')
    if (metaElement) {
      return metaElement.getAttribute("content")
    }
  }

  static updateVersion(latestVersion) {
    const metaElement = document.querySelector('meta[name="mrc-version"]')
    if (metaElement) {
      metaElement.setAttribute("content", latestVersion)
    }
  }
}
