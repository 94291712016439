import Controller from "./mrc_controller"
import MrcEvent from "./mrc_event";
// Connects to data-controller="option-form"
export default class extends Controller {

  static targets = [
    'optionTable',
    'addOptionLegBtn',
    'priceTypeSelect',
    'orderQuantity',
    'bid',
    'mid',
    'ask',
  ]

  connect() {
    document.addEventListener(MrcEvent.OPTION_LEG_CHANGED, () => {
      this.handleOptionLegsChanged()
      this.updateBidAskQuotes(true)
    })
    document.addEventListener(MrcEvent.ORDER_QUOTE_CHANGED, (event) => {
      this.handleOptionLegsChanged()
      this.updateBidAskQuotes(false)
      this.changeOrderQuantity()
    })
    this.handleOptionLegsChanged()
    this.updateView()

    // stimulus js events are unreliable
    this.orderQuantityElement().addEventListener("input", (event) => {
      this.changeOrderQuantity()
    })
    this.orderQuantityElement().value = this.getMinQuantity()
    this.lastOrderQuantity = this.orderQuantityElement().value
  }

  orderQuantityElement() {
    // Target is missing after the form gets replaced by inputing a ticker
    if (this.hasOrderQuantityTarget) {
      return this.orderQuantityTarget
    } else {
      return null
    }
  }

  updateView() {
    if (this.element.dataset.contentUrl !== undefined) {
      fetch(this.element.dataset.contentUrl)
        .then((response) => {
          if (response.status === 200) {
            response.text().then((html) => {
              this.element.innerHTML = html
            })
          }
        })
    }
  }

  // Stimulus bug... missing elements rendered async
  getOptionTable() {
    try {
      return this.optionTableTarget
    } catch (e) {
      // console.warn("missing element.. falling back to getElementById")
      return document.getElementById("option-table")
    }
  }

  getQuoteView(priceSelectType) {
    let selector = `div[data-option-form-price-select-param='${priceSelectType}'] span[data-option-form-target='${priceSelectType}']`;
    return document.querySelector(selector)
  }

  setQuote(priceSelectType, value) {
    let quoteView = this.getQuoteView(priceSelectType)
    quoteView.innerHTML = Math.abs(value) || 0
    let label = quoteView.parentElement.querySelector(".label")
    if (value > 0) {
      label.innerHTML = "DEBIT"
    } else if (value < 0) {
      label.innerHTML = "CREDIT"
    } else {
      label.innerHTML = "EVEN"
    }
  }

  updateBidAskQuotes(override) {
    let quote = this.getLastQuote()
    if (quote) {
      this.setQuote("bid", quote.bid)
      this.setQuote("mid", quote.mid)
      this.setQuote("ask", quote.ask)
      // let priceLimit = document.getElementById("price_limit")
      // if (priceLimit.value === undefined || priceLimit.value === "") {
      this.setPrice("mid", override)
      // }
    }
  }

  isEqualRatio() {
    let equalRatio = true
    let minQuantity = this.getMinQuantity()
    this.getOptionLegControllers().forEach((optionLegController) => {
      if (optionLegController != null) {
        let quantity = parseInt(optionLegController.getQuantity() || 0)
        if (quantity % minQuantity !== 0) {
          equalRatio = false
        }
      }
    })
    return equalRatio
  }

  getMinQuantity() {
    let minQuantity = Number.MAX_VALUE
    let optionLegs = this.getOptionTable().querySelectorAll("tr.option-leg-row")
    optionLegs.forEach((optionLeg) => {
      let quantityElement = optionLeg.querySelector("input[name=\"quantity[]\"]")
      let quantity = parseInt(quantityElement.value || 0)
      if (quantity < minQuantity) {
        minQuantity = quantity
      }
    })
    return minQuantity
  }

  addOptionLegBtn() {
    try {
      return this.addOptionLegBtnTarget
    } catch (e) {
      // console.warn("missing element.. falling back to getElementById")
      return document.getElementById("addOptionLegBtn")
    }
  }

  getOptionLegControllers() {
    let optionLegs = this.getOptionTable().querySelectorAll("tr.option-leg-row")
    if (optionLegs.length === 1) {
      let controller = this.application.getControllerForElementAndIdentifier(optionLegs[0], 'option-leg-row');
      if (controller) {
        return [controller]
      } else {
        return []
      }
    } else {
      return Array.from(optionLegs).map((optionLegElement) => {
        let optionLegController = this.application.getControllerForElementAndIdentifier(optionLegElement, 'option-leg-row');
        return optionLegController
      })
    }
  }

  getLastQuote() {
    let optionLegs = this.getOptionTable().querySelectorAll("tr.option-leg-row")
    if (optionLegs.length === 1) {
      let optionLegController = this.application.getControllerForElementAndIdentifier(optionLegs[0], 'option-leg-row')
      if (optionLegController && optionLegController.lastQuote) {
        const quote = optionLegController.lastQuote.option
        let orderSide = optionLegController.getOrderSide().toString()
        if (orderSide.includes("sell")) {
          return {
            bid: -quote.bid.toFixed(2),
            mid: -quote.mid.toFixed(2),
            ask: -quote.ask.toFixed(2),
          }
        } else {
          return {
            bid: quote.bid.toFixed(2),
            mid: quote.mid.toFixed(2),
            ask: quote.ask.toFixed(2),
          }
        }
      } else {
        return null
      }
    } else {
      let bid = 0
      let ask = 0
      let optionLegControllers = this.getOptionLegControllers()
      optionLegControllers.forEach((optionLegController) => {
        if (optionLegController && optionLegController.lastQuote) {
          let quote = optionLegController.lastQuote.option
          let orderSide = optionLegController.getOrderSide().toString()
          let quantity = parseInt(optionLegController.getQuantity() || 0)
          if (orderSide.includes("buy")) {
            bid += (quote.bid || 0) * quantity
            ask += (quote.ask || 0) * quantity
          } else {
            bid -= (quote.ask || 0) * quantity
            ask -= (quote.bid || 0) * quantity
          }
        }
      })
      if (bid < 0 && ask < 0) {
        let temp = bid
        bid = ask
        ask = temp
      }

      if (this.orderQuantityElement()) {
        let orderQuantity = this.orderQuantityElement().value
        if (orderQuantity > 1) {
          let equalRatio = this.isEqualRatio()
          if (equalRatio) {
            bid = bid / orderQuantity
            ask = ask / orderQuantity
          }
        }
      }

      return {
        bid: bid.toFixed(2),
        mid: ((bid + ask) / 2).toFixed(2),
        ask: ask.toFixed(2),
      }
    }
  }

  showOrHideOrderQuantity(hide) {
    let parentElement = this.orderQuantityElement().parentElement.parentElement
    if (hide) {
      parentElement.classList.add("hidden")
      parentElement.style = "opacity: 0"
    } else {
      parentElement.classList.remove("hidden")
      parentElement.style = "opacity: 1"
    }
  }

  handleOptionLegsChanged() {
    let rows = this.getOptionTable().querySelectorAll("tr")
    if (rows.length >= 4) {
      this.addOptionLegBtn().parentElement.classList.add("hidden")
    } else {
      this.addOptionLegBtn().parentElement.classList.remove("hidden")
    }

    if (this.orderQuantityElement()) {
      this.showOrHideOrderQuantity(rows.length === 1)
    }

    if (rows.length > 1) {
      document.dispatchEvent(new CustomEvent(MrcEvent.ORDER_FORM_SELECTED, {
        detail: {
          order_type: "multileg"
        }
      }))
    } else {
      document.dispatchEvent(new CustomEvent(MrcEvent.ORDER_FORM_SELECTED, {
        detail: {
          order_type: "option"
        }
      }))
    }
    this.setPrice("mid", false)
  }

  priceSelected(event) {
    this.setPrice(event.params.priceSelect, true)
  }

  getQuantity() {
    let optionLegs = this.getOptionTable().querySelectorAll("tr.option-leg-row")
    let quantity = 0
    if (optionLegs.length === 1) {
      quantity = this.getMinQuantity()
    } else {
      quantity = this.lastOrderQuantity
    }
    return quantity
  }

  setPrice(priceSelect, override) {
    let quote = this.getLastQuote()
    if (!quote) {
      return
    }

    let price = undefined
    if (priceSelect === "bid") {
      price = quote.bid || 0
    } else if (priceSelect === "mid") {
      price = quote.mid || 0
    } else if (priceSelect === "ask") {
      price = quote.ask || 0
    }

    document.dispatchEvent(new CustomEvent(MrcEvent.SET_PRICE, {
      detail: {
        price: price,
        override: override
      }
    }))
  }

  addOptionLeg() {
    let rows = this.getOptionTable().querySelectorAll("tr")
    if (rows.length >= 4) {
      alert("Maximum number of order legs reached")
      return
    }
    let optionLegRow = this.getOptionTable().querySelector("tr:first-child")
    let rowToAppend = optionLegRow.cloneNode(true)
    this.getOptionTable().append(rowToAppend)
    this.handleOptionLegsChanged()
  }

  changeOrderQuantity() {
    if (this.orderQuantityElement() === null) {
      return
    }
    let orderQuantity = this.orderQuantityElement().value || 1
    if (orderQuantity === undefined || orderQuantity <= 0 || this.lastOrderQuantity === orderQuantity) {
      return
    }

    this.getOptionLegControllers().forEach((optionLegController) => {
      if (optionLegController != null) {
        let currentQuantity = optionLegController.getQuantity() || 1
        let lastOrderQuantity = Math.max(this.lastOrderQuantity || 1, 1)
        let newQuantity = parseInt((currentQuantity / lastOrderQuantity) * orderQuantity)
        optionLegController.setQuantity(newQuantity)
      }
    })
    this.lastOrderQuantity = orderQuantity
  }

  reverseOrder() {
    this.getOptionLegControllers().forEach((optionLegController) => {
      if (optionLegController != null) {
        optionLegController.reverseLeg()
        optionLegController.fetchAndUpdateQuote(true)
      }
    })
  }
}
