import MrcController from "./mrc_controller"
import MrcEvent from "./mrc_event"
import MrcCache from "./mrc_cache"
import _ from 'underscore'
// Connects to data-controller="loader"
export default class extends MrcController {
  static values = {
    tag: String,
    globalTag: Boolean,
  }

  connect() {
    this.loaderTag = this.tagValue || this.element.dataset.contentUrl || "default"
    if (!this.hasGlobalTagValue || !this.globalTagValue) {
      this.loaderTag = `${this.loaderTag}-${_.uniqueId()}`
    }
    this.element.setAttribute("data-loader-tag-value", this.loaderTag)
    document.addEventListener(MrcEvent.LOAD(this.loaderTag), (event) => {
      let params = event.detail ? (event.detail.params || {}) : {}
      this.loadContent(params)
    })

    let shouldPreload = this.element.dataset.preload
    if (shouldPreload === undefined || shouldPreload.toString() === "true") {
      this.loadContent({})
    }

    if (this.element.dataset.reloadInterval) {
      setInterval(() => {
        this.loadContent({})
      }, parseInt(this.element.dataset.reloadInterval))
    }
  }

  shouldShowLoader() {
    return this.element.getAttribute("data-show-loader") === "true"
  }

  loadContent(params) {
    let contentUrl = this.element.dataset.contentUrl
    if (contentUrl !== undefined) {
      let loadUrl = contentUrl
      if (!_.isEmpty(params)) {
        const url = new URL(contentUrl, window.location.origin)
        for (const key in params) {
          url.searchParams.set(key, params[key])
        }
        loadUrl = url.href
      }
      let useCache = this.element.dataset.cache && this.element.dataset.cache.toString() !== "false"
      let timeout = useCache ? 10000 : 0
      if (this.shouldShowLoader()) {
        this.showLoading()
      }
      MrcCache.fetchContent(loadUrl, response => response.text(), timeout).then((html) => {
        if (html) {
          this.element.setAttribute("data-content-url", loadUrl)
          this.element.innerHTML = html
        }
        document.dispatchEvent(new CustomEvent(MrcEvent.CONTENT_LOADED))
        if (this.shouldShowLoader()) {
          this.hideLoading()
        }
      })
    }
  }
}
