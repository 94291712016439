import React, {useEffect, useRef, useState, useCallback, memo} from 'react';
import * as echarts from 'echarts';
import { format } from 'date-fns';
import _ from 'underscore';
import MrcCache from "../controllers/mrc_cache";

const PriceChart = ({ dataUrl, className = "-ml-1", width = '100%', height = "60px", minimal = true }) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [chartData, setChartData] = useState(null);

  const fetchData = useCallback((params = {}) => {
    let url = dataUrl;

    if (!_.isEmpty(params)) {
      const urlObj = new URL(dataUrl, window.location.origin);
      for (const key in params) {
        urlObj.searchParams.set(key, params[key]);
      }
      url = urlObj.href;
    }

    MrcCache.fetchContent(url, response => response.json(), 10000)
      .then(data => {
        if (data.prices) {
          setChartData(data);
        }
      });
  }, [dataUrl]);

  const createChart = useCallback(data => {
    let currentChartInstance = chartInstance;
    if (!chartInstance) {
      currentChartInstance = echarts.init(chartRef.current, 'dark', { renderer: 'svg' });
      setChartInstance(currentChartInstance);
    }
    const candlestickData = [];
    const closeData = [];
    for (let i = 0; i < data.prices.close.length; i++) {
      let oclh = [
        data.prices.open[i],
        data.prices.close[i],
        data.prices.low[i],
        data.prices.high[i]
      ];
      candlestickData.push(oclh);
      closeData.push(data.prices.close[i]);
    }

    const upColor = '#4bffb5';
    const downColor = '#ff4976';
    let previousClose = data.prices.previous_close || data.prices.close[0];
    const isUp = data.prices.close[data.prices.close.length - 1] >= previousClose;
    const labelColor = isUp ? upColor : downColor;
    let yAxisMin = Math.min(...closeData, previousClose);
    let yAxisMax = Math.max(...closeData, previousClose);
    const yRange = yAxisMax - yAxisMin;
    if (yRange > 0) {
      const yAxisBuffer = 0.1;
      yAxisMin -= yRange * yAxisBuffer;
      yAxisMax += yRange * yAxisBuffer;
    }
    const duration = data.prices.timestamp[data.prices.timestamp.length - 1] - data.prices.timestamp[0];
    const oneWeekInSeconds = 7 * 24 * 60 * 60 * 0.5;

    let dateFormatter = value => {
      const date = new Date(value * 1000);
      if (duration > oneWeekInSeconds) {
        return format(date, 'MMM-d-yy'); // Format as month day
      } else {
        return format(date, 'h:mm a'); // Format as time
      }
    };
    currentChartInstance.setOption({
      animationDuration: minimal ? 0 : 0,
      backgroundColor: 'transparent',
      grid: {
        left: minimal ? 0 : 10,
        top: minimal ? 0 : 0,
        right: minimal ? 0 : 50,
        bottom: minimal ? 0 : 30
      },
      xAxis: {
        type: 'category',
        data: data.prices.timestamp,
        alignTicks: false,
        axisLine: {
          lineStyle: {
            color: minimal ? 'transparent' : '#8392A5'
          }
        },
        axisLabel: {
          show: !minimal,
          formatter: dateFormatter
        },
        axisPointer: {
          show: !minimal,
          type: 'line',
          label: {
            color: '#000',
            backgroundColor: '#fff',
            show: false,
            formatter: data => {
              return dateFormatter(data.value);
            }
          },
        },
      },
      yAxis: {
        scale: true,
        splitNumber: 3,
        axisTick: {
          show: !minimal
        },
        min: yAxisMin,
        max: yAxisMax,
        axisLine: {
          lineStyle: {
            color: minimal ? 'transparent' : '#8392A5'
          }
        },
        splitLine: {
          show: !minimal,
          lineStyle: {
            color: '#1c1c1c'
          }
        },
        position: 'right'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: {
            backgroundColor: '#6a7985'
          }
        },
      },
      series: [
        {
          type: 'line',
          data: closeData,
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: isUp ? upColor : downColor
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: isUp ? upColor : downColor },
              { offset: 1, color: 'transparent' }
            ]),
            opacity: 0.45,
            origin: 'start'
          },
          markLine: minimal ? {} : {
            animation: false,
            symbol: ['none', 'none'],
            emphasis: {
              disabled: true
            },
            label: {
              show: true,
              formatter: params => params.value,
              backgroundColor: labelColor,
              color: isUp ? '#000' : '#fff',
              padding: 5
            },
            data: [
              {
                yAxis: data.prices.close[data.prices.close.length - 1],
                lineStyle: {
                  color: labelColor,
                  width: 0
                }
              },
              {
                yAxis: previousClose,
                lineStyle: {
                  color: '#666',
                  type: 'dashed',
                  dashOffset: 0
                },
                label: {
                  show: true,
                  formatter: minimal ? "" : `${previousClose}`,
                  position: 'start',
                  color: '#ccc',
                  offset: [50, isUp ? -15 : 15],
                  backgroundColor: '#00000099',
                  borderColor: 'transparent'
                }
              }
            ]
          }
        }
      ]
    });
  }, [chartInstance, minimal]);

  useEffect(() => {
    fetchData();
    return () => {
      if (chartInstance) {
        chartInstance.dispose();
        setChartInstance(null);
      }
    }
  }, [dataUrl, fetchData]);

  useEffect(() => {
    if (chartData) {
      createChart(chartData);
    }
  }, [chartData, createChart]);

  return <div className={className} ref={chartRef} style={{ width: width, height: height }}></div>;
};

export default memo(PriceChart);