import { Controller } from "@hotwired/stimulus"
import MrcEvent from "./mrc_event"
import Tracker from "../components/tracker"

// Connects to data-controller="filter"
export default class extends Controller {
  connect() {
    this.params = {}
    this.element.querySelectorAll('select').forEach(select => {
      this.params[select.name] = select.value;
    })
  }

  selectFilter(event) {
    this.params[event.target.name] = event.target.value
    Tracker.track("select_filter", this.params)
    let loaderTag = this.element.getAttribute("data-loader-tag")
    if (loaderTag) {
      document.dispatchEvent(new CustomEvent(MrcEvent.LOAD(loaderTag), {
        detail: {
          params: this.params
        }
      }))
    } else {
      console.error("set data-loader-tag on filter controller")
    }
  }
}
