// Define the formatMoney function
function formatMoney(amount, includePlus = false) {
  const isPositive = amount >= 0;
  const formattedAmount = Math.abs(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const plusOrBlank = includePlus ? "+" : "";
  const cleanedAmount = formattedAmount.replace(/\.00$/, '');
  return isPositive ? `${plusOrBlank}${cleanedAmount}` : `-${cleanedAmount}`;
}

const pctPlus = (num) => {
  const sign = num > 0 ? '+' : '';
  return `${sign}${num.toFixed(2)}`;
};

// Export the function to be used in other files
export { formatMoney, pctPlus };