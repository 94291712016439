import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sheets"
export default class extends Controller {
  connect() {
  }

  updateFormParams() {
    let symbol = this.element.querySelector("input#symbol").value
    let elements = this.element.querySelectorAll("input[type=\"radio\"]:checked")
    let query_str = `?symbol=${symbol}`
    elements.forEach((element) => {
      // console.log(`${element.name}=${element.value}`)
      query_str += `&${element.name}=${element.value}`
    })
    Turbo.visit(query_str)
  }

  changeSymbol() {
    if (this.symbolTimeout) {
      clearTimeout(this.symbolTimeout)
    }
    this.symbolTimeout = setTimeout(() => {
      this.updateFormParams()
    }, 1000)
  }
}
