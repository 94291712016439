import MrcController from "./mrc_controller";

// Connects to data-controller="root"
export default class extends MrcController {

  static targets = ["loading"]

  connect() {
    this.hideLoading = this.hideLoading.bind(this)
    this.showLoading = this.showLoading.bind(this)
    document.addEventListener("turbo:render", this.hideLoading)
    document.addEventListener("turbo:frame-render", this.hideLoading)
    document.addEventListener("turbo:click", this.showLoading)
    document.addEventListener("mrc:loading", this.showLoading)
    document.addEventListener('turbo:frame-render', function(event) {
      const frame = event.target;
      const responseHeaders = event.detail.fetchResponse.response.headers;
      let deployedVersion = responseHeaders.get('X-Mrc-Version');
      window.Blaze.updateVersion(deployedVersion)
    })
  }

  disconnect() {
    document.removeEventListener("turbo:render", this.hideLoading)
    document.removeEventListener("turbo:frame-render", this.hideLoading)
    document.removeEventListener("turbo:click", this.showLoading)
    document.removeEventListener("mrc:loading", this.showLoading)

  }

  showLoading = () => {
    // prevent flashing loader on fast pages by delaying the loader by 200ms
    this.loaderTimeout = setTimeout(() => {
      this.loadingTarget.classList.add("opacity-100");
      this.loadingTarget.style.pointerEvents = 'auto';
    }, 200);

    // If the page hasn't loaded after 8 seconds, hide the loader.
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer);
    }
    this.timeoutTimer = setTimeout(() => {
      this.hideLoading();
    }, 8000);
  }

  hideLoading = () => {
    // If the loader hasn't been shown yet, clear the timeout.
    if (this.loaderTimeout) {
      clearTimeout(this.loaderTimeout);
      this.loaderTimeout = null;
    }

    this.loadingTarget.classList.remove("opacity-100");
    this.loadingTarget.style.pointerEvents = 'none';
    this.timeoutTimer = null;
  }
}
