import { Controller } from "@hotwired/stimulus"
import MrcEvent from "./mrc_event"
import _ from "underscore"

// Connects to data-controller="filter-page"
export default class extends Controller {
  static targets = [ "table" ]
  static outlets = [ "loader", "rangepicker" ]

  connect() {
    this.params = Object.fromEntries(new URLSearchParams(location.search))
    this.listenForRangeChange()
  }

  listenForRangeChange() {
    document.addEventListener(MrcEvent.RANGE_SELECTED, (event) => {
      let updatedParams = event.detail ? (event.detail.params || {}) : {}
      _.extend(this.params, updatedParams)
      this.load()
    })
  }

  updateUrlFromParams() {
    let url = new URL(window.location.href)
    let searchParams = new URLSearchParams(this.params);
    url.search = searchParams.toString()
    window.history.replaceState({}, document.title, url.toString());
  }
  load() {
    this.updateUrlFromParams()
    this.loaderOutlet.loadContent(this.params)
  }

  selectPicker(event) {
    let ticker = event.target.value
    if (ticker) {
      _.extend(this.params, {ticker: ticker})
      this.load()
    }
  }

  downloadCSV(event) {
    let range = this.rangepickerOutlet.getRange()
    let start = range.startTime.format('YYYY-MM-DD')
    let end = range.endTime.format('YYYY-MM-DD')
    let prefix = this.element.getAttribute('data-filter-page-csv-prefix') || "BLAZE"
    let filename = `${prefix}_${start}_${end}`
    if (this.params.ticker) {
      filename = `${filename}_${this.params.ticker}`
    }
    this.startDownload(this.tableContent(), `${filename}.csv`)
  }

  tableContent() {
    let table = this.tableTarget
    let csv = [];
    for (let row of table.rows) {
      let cols = [];
      for (let cell of row.cells) {
        cols.push(cell.innerText.replace(/,/g, '')); // remove commas if present
      }
      csv.push(cols.join(","));
    }
    return csv.join("\n")
  }

  startDownload(content, fileName) {
    let downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(content);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
