import Controller from "./mrc_controller"

// Connects to data-controller="input-text"
export default class extends Controller {
  connect() {
    this.updateTimeout = null
    this.element.addEventListener("keyup", this.updateWithDelay)
    this.element.addEventListener("input", this.updateWithDelay)
  }

  updateWithDelay = (event) => {
    if (this.updateTimeout != null) {
      clearTimeout(this.updateTimeout)
    }

    this.updateTimeout = setTimeout(() => {
      this.submitInput(event)
    }, 1000)
  }

  submitInput(event) {
    event.preventDefault()
    event.stopPropagation()
    if (event.key === 'Enter' || event.keyCode === 13 || event.type === "input" || event.type === "keyup") {
      const value = event.target.value
      if (value === undefined || value === "") {
        return
      }
      this.element.setAttribute("disabled", "true")
      this.element.classList.add("opacity-20")
      this.element.classList.add("animate-pulse")
      const url = this.element.dataset.inputTextUrl
      const paramsStr = this.element.dataset.inputTextParams
      let params = {}
      if (paramsStr) {
        params = JSON.parse(paramsStr)
      }
      params[this.element.name] = value
      fetch(url, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
      }).then((response) => response.json()).then((data) => {
        if (data.success) {
          this.element.removeAttribute("disabled")
          this.element.classList.remove("opacity-20")
          this.element.classList.remove("animate-pulse")
          // Turbo.visit(location.toString())
        } else {
          alert("Error")
        }
      })
    }
  }
}
