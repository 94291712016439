import { Controller } from "@hotwired/stimulus"
import { AsYouType } from 'libphonenumber-js';

// Connects to data-controller="phonefield"
export default class extends Controller {
  connect() {
    const phoneNumberInput = this.element;
    phoneNumberInput.addEventListener('input', (event) => {
      let phoneInputStr = event.target.value
      if (phoneInputStr.includes('(') && !phoneInputStr.includes(')')) {
        const tempValue = phoneInputStr.replace('(', '');
        phoneInputStr = tempValue.slice(0, tempValue.length - 1);
      } else {
        phoneInputStr = new AsYouType('US').input(phoneInputStr)
      }
      event.target.value = phoneInputStr
    });
  }
}
