import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static classes = ["hidden", "visible"]
  static targets = ["toggle"]

  connect() {
  }

  show() {
    this.toggleTarget.classList.remove("hidden")
  }
  hide() {
    this.toggleTarget.classList.add("hidden")
  }
}
