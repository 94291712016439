import MrcController from './mrc_controller'
import MrcEvent from "./mrc_event";

// Connects to data-controller="range"
export default class extends MrcController {
  connect() {
    this.calendarTarget = this.element.querySelector("[data-rangepicker-target='calendar']")
    document.addEventListener(MrcEvent.RANGE_SELECTED, (event) => {
      let params = event.detail ? (event.detail.params || this.params) : this.params
      this.clearSelected()
      if (this.calendarTarget) {
        this.calendarTarget.classList.add(...this.rangeSelectedClass())
      }
      this.populateCharts(params)
    })
  }

  rangeSelectedClass() {
    let rangePickerElement = this.element.querySelector("[data-controller='rangepicker']")
    let cssClass = rangePickerElement.getAttribute("data-selected-class") || "text-red-500"
    return cssClass.split(" ")
  }

  selectRange(event) {
    event.preventDefault()
    this.clearSelected()
    event.srcElement.classList.add(...this.rangeSelectedClass())
    let startTime = event.srcElement.getAttribute("data-start-time")
    this.populateCharts({
      start_time: startTime
    })
  }

  clearSelected() {
    let rangePickerElement = this.element.querySelector("[data-controller='rangepicker']")
    if (rangePickerElement) {
      let allLinks = rangePickerElement.querySelectorAll("a")
      allLinks.forEach((link) => {
        link.classList.remove(...this.rangeSelectedClass())
      })
    }
  }
}
