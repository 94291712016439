import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="redirect"
export default class extends Controller {
  connect(){
    this.observer = new MutationObserver(this.srcListener)
    this.observer.observe(this.element, { attributes: true })
  }
  disconnect() {
    this.observer.disconnect()
  }

  srcListener(mutationList, observer) {
    for (const mutation of mutationList) {
      if (mutation.type === "attributes" && mutation.attributeName === "src") {
        let nextSrc = mutation.target.attributes['src'].value;
        // force reload invalid sessions, alternatively can use meta header: <meta name="turbo-visit-control" content="reload">
        if (nextSrc.match(/\/account\/connect/) || nextSrc.match(/\/login/)) {
          window.location = nextSrc
        }
      }
    }
  }
}
