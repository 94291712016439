// Entry point for the build script in your package.json
import "@hotwired/stimulus"
import "./controllers"
import "@hotwired/turbo-rails"
import "./blaze"
import "./htmx"
import "htmx.org"

import * as echarts from 'echarts/core'
import { CandlestickChart } from 'echarts/charts'
import {
  AxisPointerComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
} from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers'
echarts.use([
  AxisPointerComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
  CandlestickChart,
  SVGRenderer
]);
