// app/javascript/controllers/headlines_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["headline"]
  static values = { index: Number, interval: Number }

  initialize() {
    this.indexValue = 0
    this.intervalValue = this.intervalValue || 3000
  }

  connect() {
    this.showNextHeadline()
    this.startRotation()
  }

  disconnect() {
    this.stopRotation()
  }

  showNextHeadline() {
    this.headlineTargets.forEach((headline, index) => {
      let hideHeadline = index !== this.indexValue;
      headline.classList.toggle("opacity-0", hideHeadline)
      headline.classList.toggle("opacity-100", !hideHeadline)
      setTimeout(() => {
        headline.classList.toggle("hidden", hideHeadline)
      }, 200)
    })
    this.indexValue = (this.indexValue + 1) % this.headlineTargets.length
  }

  startRotation() {
    this.timer = setInterval(() => {
      this.showNextHeadline()
    }, this.intervalValue)
  }

  stopRotation() {
    clearInterval(this.timer)
  }
}