import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="option-table"
export default class extends Controller {
  static classes = ["atmRow", "otmRow", "itmRow"]

  connect() {
    this.addClasses()
  }

  appear(entry, observer) {
    this.addClasses()
  }

  addClasses() {
    let strikes = []
    let lastPrice = parseFloat(this.element.getAttribute("data-last-price"))
    let tableRows = this.element.querySelectorAll(".option-table-body > tr")
    let strikeRowMap = {}
    tableRows.forEach((element) => {
      let strike = parseFloat(element.getAttribute("data-strike"));
      strikes.push(strike)
      strikeRowMap[strike] = element
    })
    let closest_strike = strikes.map((x) => x).reduce(this.closestFn(lastPrice))
    let atmRow = strikeRowMap[closest_strike]

    let putAsk = atmRow.getAttribute("data-put-ask")
    let callAsk = atmRow.getAttribute("data-call-ask")
    if (putAsk && callAsk) {
      const expectedMove = parseFloat(putAsk) + parseFloat(callAsk)
      // console.log(`Last Price: ${lastPrice} Strike: ${closest_strike} expected=${expectedMove}`)
      tableRows.forEach((element) => {
        let strike = parseFloat(element.getAttribute("data-strike"))
        if ((strike < (lastPrice - expectedMove)) || strike > (lastPrice + expectedMove)) {
          element.classList.add(...this.otmRowClasses)
        }
      })
    }
    let rowHeight = atmRow.offsetHeight
    let numRows = this.element.offsetHeight / rowHeight
    // Place the atm rows in the center of the table
    const rowOffset = -3
    let scrollTo = atmRow.offsetTop - (((numRows / 2) + rowOffset) * rowHeight)
    // let scrollTo = atmRow.offsetTop - rowHeight
    this.element.scrollTo({ top: scrollTo })
    atmRow.querySelectorAll("td").forEach((element) => {
      element.classList.add(...this.atmRowClasses)
    })
  }

  closestFn(goal) {
    return function (prev, curr) {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    };
  }
}
