import React from "react";

const ChevronLeftIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon"
       className="size-4 fill-zinc-400 dark:fill-zinc-500">
    <path fillRule="evenodd"
          d="M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z"
          clipRule="evenodd"></path>
  </svg>
}

export default ChevronLeftIcon;