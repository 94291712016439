import React, {useState} from 'react';
import {formatMoney} from "../utils";
import EmbedContainer from "./embed_container";

const PositionsList = ({positions, className}) => {
  return positions && positions.length > 0 && <EmbedContainer className={className}>
    <span className="text-xs uppercase text-zinc-400 bg-zinc-900 text-center px-2 py-1 rounded-t-lg">Positions</span>
    <div className="flex flex-col divide-y divide-zinc-800">
      {positions.map((position, index) => (
        <Position key={index} position={position}/>
      ))}
    </div>
  </EmbedContainer>;
};

const Position = ({position}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => setIsOpen(!isOpen);

  const getColorClass = (value) => {
    if (value > 0) return 'text-green-500';
    if (value === 0) return 'text-zinc-400';
    if (value < 0 && value > -30) return 'text-rose-300';
    return 'text-rose-400';
  };

  return (
    <div className="mb-4 pt-4 pl-4">
      {/* Title row */}
      <div onClick={toggleDetails} className="w-full flex flex-row gap-x-2">
        <div className="grow block">
          <a className="text-lg" href={`${position.link_url}`}>
            {position.description}
          </a>
          <div className="text-sm text-zinc-500">
            <span>Qty: <span className="text-zinc-400">{position.quantity}x</span></span>
            <span className="ml-2">Value: <span className="text-zinc-400">{formatMoney(position.total_value)}</span></span>
          </div>
        </div>
        <div className="flex flex-col mr-4">
          <div className={`text-right ${getColorClass(position.total_gain_pct)}`}>
            {position.total_gain_pct}%
          </div>
          <div className={`text-right ${getColorClass(position.total_gain)}`}>
            {formatMoney(position.total_gain, true)}
          </div>
        </div>
        <div className="flex items-center mr-4">
          {isOpen ? <ChevronDownIcon/> : <ChevronRightIcon/>}
        </div>
      </div>
      {/* Detail row */}
      {isOpen && (
        <div>
          <div className="w-full flex flex-row" onClick={toggleDetails}>
            <div className="grow block">
              {position.delta && (
                <div className="text-sm text-zinc-500">
                  Delta: {position.delta}
                  {position.theta && (
                    <span className={`ml-2 ${position.theta < 0 && position.theta.abs > position.total_value * 0.04 ? 'text-orange-400' : 'text-zinc-4500'}`}>
                      Theta: <span className={position.theta > 0 ? 'text-sky-400' : ''}>{position.theta}</span>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Row information */}
          <div className="mt-4 border-t border-zinc-800">
            <table className="w-full table-auto border-collapse sticky top-0 mt-2 py-2 pl-4">
              <thead className="text-xs text-left font-medium text-gray-500 rounded-lg">
              <tr className="pr-8 sticky top-0 align-top relative shadow-xl">
                <th scope="col" className="sticky top-0 text-center font-medium text-gray-400">Qty</th>
                <th scope="col" className="sticky top-0 text-right font-medium text-gray-400">
                  <div>Last</div>
                  <div className="text-zinc-500">Cost</div>
                </th>
                <th scope="col" className="sticky top-0 text-right font-medium text-gray-400">
                  <div>Total</div>
                  <div className="text-zinc-500">Cost</div>
                </th>
                <th scope="col" className="sticky top-0 text-right font-medium text-gray-400">
                  <div>Day Gain</div>
                </th>
                <th scope="col" className="sticky pr-4 top-0 text-right font-medium text-gray-400">
                  <div>Total Gain</div>
                </th>
              </tr>
              </thead>
              <tbody className="text-lg divide-y divide-x divide-zinc-800">
              <tr className="hover:bg-black">
                <td className="align-top p-2 text-center">
                  <div>{position.quantity}</div>
                </td>
                <td className="align-top py-2 text-right">
                  <div className="text-zinc-200">{position.last?.mid}</div>
                  <div className="text-zinc-500">{position.price_paid}</div>
                </td>
                <td className="align-top py-2 text-right text-zinc-400">
                  <div className="text-zinc-200">{position.total_value}</div>
                  <div className="text-zinc-500">{position.cost_basis}</div>
                </td>
                <td className="align-top py-2 text-right">
                  <div className={`text-right ${getColorClass(position.day_gain_pct)}`}>
                    {position.day_gain_pct}%
                  </div>
                  <div className={`text-right ${getColorClass(position.day_gain)}`}>
                    {position.day_gain}
                  </div>
                </td>
                <td className="align-top py-2 pr-4 md:pr-0 text-right">
                  <div className={`text-right ${getColorClass(position.total_gain_pct)}`}>
                    {position.total_gain_pct}%
                  </div>
                  <div className={`text-right ${getColorClass(position.total_gain)}`}>
                    {position.total_gain}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          {/* Actions */}
          <div className="gap-4 pr-4 my-4 justify-center flex text-center w-full">
            <a className="flex-grow" href={position.open_url}>
              <div className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-zinc-600 hover:bg-dgreen-700">
                Add
              </div>
            </a>
            <a className="flex-grow" href={position.close_url}>
              <div className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dgreen-600 hover:bg-dgreen-700">
                Close
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

const ChevronRightIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ChevronDownIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default PositionsList;