import {parseOptionKey} from "../option_chain";
import React from "react";

const Order = ({order}) => {

  const orderSide = (order) => {
    return (order.side || order.legs[0].side);
  }

  const orderStatusColor = (prefix, orderStatus) => {
    switch (orderStatus) {
      case "rejected":
        return `${prefix}-rose-400`;
      case "filled":
        return `${prefix}-dgreen-400`;
      case "open":
      case "partially_filled":
        return `${prefix}-yellow-500`;
      default:
        return `${prefix}-zinc-500`;
    }
  };

  const orderSideToText = (orderSide) => {
    return orderSide.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const optionSymbolToDesc = (optionSymbol) => {
    const params = parseOptionKey(optionSymbol);
    const expiration = new Date(params.exp_date).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
    const strike = params.strike.toFixed(2);
    return `${params.ticker} ${expiration} ${strike} ${params.option_type}`;
  };

  const fillPriceDesc = (order) => {
    let fillPrice = order.avg_fill_price && order.avg_fill_price !== 0 ? order.avg_fill_price : null;
    let suffix = "";
    if (order.class !== "equity") {
      suffix = orderSide(order).includes("buy") ? "db" : "cr";
    }
    return fillPrice ? `${fillPrice.toFixed(2)} ${suffix}`.trim() : "-";
  };

  const orderPriceDesc = (order) => {
    if (order.type === "market") return "Market";

    let suffix = "";
    if (order.class !== "equity") {
      if (order.type === "limit") {
        suffix = orderSide(order).includes("buy") ? "db" : "cr";
      } else if (["debit", "credit"].includes(order.type)) {
        suffix = order.type === "debit" ? "db" : "cr";
      }
    }

    return order.price ? `${parseFloat(order.price).toFixed(2)} ${suffix}`.trim() : "-";
  };

  const color = orderStatusColor("text", order.status);
  const orderTime = new Date(order.transaction_date);
  const orderColor = orderSide(order).includes("close") ? "text-rose-400" : "text-green-400";
  return (
    <div className="w-full" >
      <div className="justify-center grid-cols-10 grid divide-x divide-zinc-900">
        <div className="text-sm p-2 col-span-3 flex flex-col bg-black">
          <div className="flex justify-start w-full items-start text-sm text-zinc-500 uppercase">
            <div className="font-semibold flex flex-col">
              <div className={`flex flex-row justify-center items-center font-semibold ${color}`}>
                {order.id && <span className="mr-1 font-semibold text-zinc-500">#{order.id.toString().slice(-4)}</span>}
              </div>
            </div>
            <div className="hidden text-rose-400 font-semibold flex flex-row items-center">
              <span>Copytrader Error</span>
            </div>
          </div>

          <span className="text-dgreen-200 text-lg">{order.symbol}</span>
          <div className="mt-2 text-zinc-500 flex flex-col">
            <span>{orderTime.toLocaleDateString('en-US', {weekday: 'long'})}</span>
            <span className="text-zinc-300">{`${orderTime.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})} ET`}</span>
            <span className="text-sm">{orderTime.toLocaleDateString('en-US')}</span>
          </div>
        </div>
        <div className="p-2 px-4 col-span-7 bg-black">
          <div className="flex flex-col">
            <div className="text-lg text-white">
              {order.class === "option" ? (
                <span className="text-left">
                    <span>{optionSymbolToDesc(order.option_symbol)}</span>
                  </span>
              ) : order.descriptions ? (
                <div className="text-left flex flex-col">
                  {order.descriptions.map((description, index) => {
                    return <div key={index}>{description}</div>
                  })}
                </div>
              ) : (
                <>
                  <span>{order.symbol} Stock</span>
                </>
              )}
            </div>
            <div className="text-sm mt-2 flex flex-col max-w-[150px] divide-y gap-y-1 divide-zinc-800">
              <div className="flex flex-row justify-between">
                <div className="text-zinc-500">Side</div>
                <span className={orderColor}>{orderSideToText(orderSide(order))}</span>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-zinc-500">Qty</div>
                <span className="text-white">{order.quantity}x</span>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-zinc-500">Price</div>
                <span className="text-white">{orderPriceDesc(order)}</span>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-zinc-500">Status</div>
                <span className="text-dgreen-500">
                  {order.status}
                  {order.status === "filled" && <span>{/* Render check_circle icon here */}</span>}
                  {order.status === "rejected" && <span>{/* Render exclamation_circle icon here */}</span>}
                </span>
              </div>
              {order.avg_fill_price && (
                <div className="flex flex-row justify-between">
                  <div className="text-zinc-500">Filled</div>
                  <span className="text-yellow-500">{fillPriceDesc(order)}</span>
                </div>
              )}
              <div className="flex flex-row justify-between">
                <div className="text-zinc-500">Term</div>
                <span className="text-white">{order.duration.toUpperCase()}</span>
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row gap-4">
            {["open", "pending", "partially_filled"].includes(order.status) && (
              <a className="grow" data-turbo-frame="main" href="#">
                <button data-controller="button" type="submit" className="btn bg-zinc-600 text-white flex flex-row justify-center">
                  Cancel
                </button>
              </a>
            )}
            <a className="hidden grow" data-turbo-frame="main" href="#">
              <button data-controller="button" type="submit" className="btn bg-black border-2 text-dgreen-300 border-dgreen-300 flex flex-row justify-center">
                Copy
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;