import { Controller } from "@hotwired/stimulus"
import {createRoot} from "react-dom/client";
import React from "react";
import RoboTraderComponent from "../components/robotrader_component";

// Connects to data-controller="robotrader"
export default class extends Controller {
  static values = {
    dataJson: Object,
  }
  connect() {
    createRoot(this.element).render(<RoboTraderComponent data={this.dataJsonValue} />);
  }
}
