import { ColorType, createChart, CrosshairMode } from 'lightweight-charts'
import RangeController from './range_controller'
import MrcCache from './mrc_cache'
import _ from "underscore"
// Connects to data-controller="option-price-chart"
export default class extends RangeController {
  static targets = ['chart']

  connect() {
    super.connect()
    this.width = this.element.scrollWidth
    this.chart = this.createChart(this.width)
    this.populateCharts()
  }

  createChart(width) {
    return createChart(this.chartTarget, {
      width: width,
      height: 300,
      layout: {
        background: { type: ColorType.Solid, color: '#000000' },
        textColor: '#999999',
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        horzLine: {
          labelBackgroundColor: "#48e8cc",
        },
        vertLine: {
          labelBackgroundColor: "#48e8cc",
        },
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0)',
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
    });
  }

  populateCharts(params = {}) {
    let dataUrl = this.element.dataset.dataUrl;
    if (!_.isEmpty(params)) {
      const url = new URL(dataUrl, window.location.origin)
      for (const key in params) {
        url.searchParams.set(key, params[key])
      }
      dataUrl = url.href
      this.element.setAttribute("data-data-url", dataUrl)
    }

    this.showLoading()
    MrcCache.fetchContent(dataUrl, (response) => response.json())
      .then((data) => {
        const option_prices = data["option_prices"]
        const option_prices_chartdata = option_prices.map((point) => {
          return {
            time: point["t"] / 1000,
            close: point["c"],
            open: point["o"],
            low: point["l"],
            high: point["h"],
          }
        })
        const volume_chartdata = option_prices.map((point) => {
          return {
            time: point["t"] / 1000,
            value: point["v"],
          }
        })

        if (this.candleSeries == null) {
          this.candleSeries = this.chart.addCandlestickSeries()
        }
        this.candleSeries.setData(option_prices_chartdata)

        if (this.volumeSeries == null) {
          this.volumeSeries = this.chart.addHistogramSeries({
            color: "rgba(36,189,255,0.65)",
            priceFormat: {
              type: 'volume',
            },
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId
          })
        }
        this.volumeSeries.priceScale().applyOptions({
          // set the positioning of the volume series
          scaleMargins: {
            top: 0.92, // highest point of the series will be x% away from the top
            bottom: 0,
          },
        })
        this.volumeSeries.setData(volume_chartdata)
        this.chart.timeScale().fitContent()
        this.hideLoading()
      })
  }
}
