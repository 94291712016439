import Controller from "./mrc_controller"
import morphdom from "morphdom"
// Connects to data-controller="portfolio"
export default class extends Controller {

  disconnect() {
    clearInterval(this.refreshInterval)
  }

  connect() {
    this.refreshInterval = setInterval(() => {
      this.update()
    }, 150000)
  }


  update() {
    if (!document.hasFocus()) {
      return
    }
    fetch(this.element.dataset.viewUrl)
      .then((response) => response.text())
      .then((html) => {
        morphdom(this.element, html)
        let balanceChart = this.element.querySelector('[data-controller="balance-chart"]')
        if (balanceChart) {
          let balanceController = this.application.getControllerForElementAndIdentifier(balanceChart, 'balance-chart')
          balanceController.disconnect()
          balanceController.connect()
        }
      }).catch((error) => {
      console.error('Error:', error);
    })
  }
}
