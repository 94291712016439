import Controller from "./mrc_controller"

// Connects to data-controller="button"
export default class extends Controller {
  connect() {
    this.originalHtml = this.element.innerHTML
    this.element.addEventListener("click", () => {
      let loadingHtml = "<div class='w-6 h-6 mx-auto justify-center items-center flex'><svg class=\"animate-spin h-10 w-10 text-white\" xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\">\n" +
        "        <circle class=\"opacity-25\" cx=\"12\" cy=\"12\" r=\"10\" stroke=\"currentColor\" stroke-width=\"4\"></circle>\n" +
        "        <path class=\"opacity-75\" fill=\"currentColor\" d=\"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z\"></path>\n" +
        "      </svg></div>"

      if (!this.element.innerHTML.includes("animate-spin")) {
        let container = document.createElement('div');
        container.innerHTML = loadingHtml
        container.className = "w-6 h-6 mx-auto justify-center items-center flex flex-row"
        this.element.innerHTML = container.innerHTML
        if (this.element.dataset.postUrl) {
          let jsonBody = this.element.dataset.postParams ? this.element.dataset.postParams : undefined
          fetch(this.element.dataset.postUrl, {
            method: 'post',
            headers: {
              "X-CSRF-Token": this.csrfToken(),
              'Content-Type': 'application/json'
            },
            body: jsonBody,
          }).then((response) => response.json()).then((data) => {
            if (data.success) {
              if (this.resetTimer) {
                clearTimeout(this.resetTimer)
                this.resetTimer = undefined
              }
            } else {
              this.resetElement()
            }
            Turbo.visit(window.location.href)
          })
        }
        this.resetTimer = setTimeout(() => {
          this.resetElement()
        }, 5000)
      }
    })
  }

  resetElement() {
    this.element.innerHTML = this.originalHtml
  }
}
