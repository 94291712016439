import {Controller} from "@hotwired/stimulus"
import moment from 'moment'
import MrcEvent from "./mrc_event";
import flatpickr from "flatpickr"

// Connects to data-controller="rangepicker"
export default class extends Controller {
  static targets = ['picker']
  static values = {
    start: String,
    end: String,
  }

  connect() {
    this.setRange({
      startTime: this.parseDate(this.startValue),
      endTime: this.parseDate(this.endValue)
    })
    let flatpickerConfig = {
      mode: "range",
      dateFormat: "n/j/y",
      defaultDate: [this.startTime.toDate(), this.endTime.toDate()],
      onClose: (selectedDates, dateStr, instance) => {
        if (selectedDates.length === 2) {
          let startTime = moment(selectedDates[0])
          let endTime = moment(selectedDates[1])
          this.dateChanged(startTime, endTime)
        }
      }
    }
    this.flatpicker = flatpickr(this.pickerTarget, flatpickerConfig)
  }

  openPicker() {
    this.flatpicker.open()
  }

  dateChanged(startTime, endTime) {
    if (this.startTime == null || this.endTime == null) {
      return
    }

    if (startTime.toDate().getTime() !== this.startTime.toDate().getTime() || endTime.toDate().getTime() !== this.endTime.toDate().getTime()) {
      this.setRange({
        startTime: startTime,
        endTime: endTime,
      })
      this.navigate()
    }
  }

  parseDate(dateString) {
    return moment(dateString, ["YYYY-MM-DD", "MMM DD 'YY"])
  }

  navigate() {
    document.dispatchEvent(new CustomEvent(MrcEvent.RANGE_SELECTED, {
      detail: {
        params: {
          start_time: this.startTime.format("YYYY-MM-DD"),
          end_time: this.endTime.format("YYYY-MM-DD"),
        }
      }
    }))
  }

  getRange() {
    return {
      startTime: this.startTime,
      endTime: this.endTime
    }
  }

  setRange(config) {
    this.startTime = config.startTime
    this.endTime = config.endTime
    if (!this.startTime.isValid() || !this.endTime.isValid()) {
      this.startTime = moment()
      this.endTime = moment()
    }
  }

  changeRangeByMonth(direction) {
    const newStartTime = this.startTime.clone().add(direction, 'months');
    const newEndTime = moment(newStartTime).endOf('month');
    this.dateChanged(newStartTime, newEndTime)
  }

  changeRangeByDistance(distance) {
    const newStartTime = this.startTime.clone().add(distance, 'days');
    const newEndTime = this.endTime.clone().add(distance, 'days');
    this.dateChanged(newStartTime,newEndTime)
  }

  distance() {
    let duration = moment.duration(this.endTime.diff(this.startTime))
    return duration.asDays()
  }

  previousPeriod() {
    if (this.isFullMonthRange()) {
      this.changeRangeByMonth(-1)
    } else {
      this.changeRangeByDistance(-this.distance())
    }
  }

  nextPeriod() {
    if (this.isFullMonthRange()) {
      this.changeRangeByMonth(1)
    } else {
      this.changeRangeByDistance(this.distance())
    }
  }

  isFullMonthRange() {
    const startOfMonth = this.startTime.clone().startOf('month');
    const endOfMonth = this.endTime.clone().endOf('month');
    return this.startTime.isSame(startOfMonth, 'day') && this.endTime.isSame(endOfMonth, 'day');
  }
}
