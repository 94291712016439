import React from 'react';
import Order from "./order";
import clsx from "clsx";

const OrderList = ({orders, className}) => {
  return (
    <div className={clsx([
      className,
      "my-4 flex flex-col w-fit border border-zinc-900 hover:border-zinc-700 rounded-lg"])}>
      <span className="block w-full text-xs uppercase text-zinc-500 bg-zinc-900 text-center px-2 py-1 rounded-t-lg">
        Orders
      </span>

      <div className="divide-y divide-zinc-800 flex flex-col space-y-0 rounded-lg">
        {orders.map((order, index) => (
          <Order
            order={order}
            key={order.id || index}
          />
        ))}
      </div>
    </div>
  );
};

export default OrderList;

