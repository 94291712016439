import Controller from "./mrc_controller"
import MrcEvent from "./mrc_event";

// Connects to data-controller="order-form"
export default class extends Controller {
  static targets = [
    'priceLimit',
    'symbolInput',
    'marketQuoteView',
    'optionSwitch',
    'optionForm',
    'orderStructureInput',
    'stockForm'
  ]

  connect() {
    let orderType = this.element.dataset.orderType
    if (orderType === "STOCK") {
      this.switchToStockForm()
    } else {
      this.switchToOptionForm()
    }
  }

  switchToOptionForm() {
    let switchButton = this.optionSwitchTarget.querySelector(".switch-button")
    let labelLeft = this.optionSwitchTarget.querySelector(".label-l")
    let labelRight = this.optionSwitchTarget.querySelector(".label-r")
    switchButton.classList.remove("translate-x-5")
    switchButton.classList.add("translate-x-0")
    labelRight.classList.remove("text-zinc-200")
    labelRight.classList.add("text-zinc-600")
    labelLeft.classList.remove("text-zinc-600")
    labelLeft.classList.add("text-zinc-200")
    this.stockFormTarget.classList.add("hidden")
    this.optionFormTarget.classList.remove("hidden")
    this.element.dataset.orderType = "OPTION"
    this.orderStructureInputTarget.setAttribute("value", "OPTION")
    document.dispatchEvent(new CustomEvent("mrc:order_form_selected", {
      detail: {
        order_type: "option"
      }
    }))
  }

  // Switch from Option -> Stock
  switchToStockForm() {
    let switchButton = this.optionSwitchTarget.querySelector(".switch-button")
    let labelLeft = this.optionSwitchTarget.querySelector(".label-l")
    let labelRight = this.optionSwitchTarget.querySelector(".label-r")
    switchButton.classList.remove("translate-x-0")
    switchButton.classList.add("translate-x-5")
    labelLeft.classList.remove("text-zinc-200")
    labelLeft.classList.add("text-zinc-600")
    labelRight.classList.remove("text-zinc-600")
    labelRight.classList.add("text-zinc-200")
    this.stockFormTarget.classList.remove("hidden")
    this.optionFormTarget.classList.add("hidden")
    this.element.dataset.orderType = "STOCK"
    this.orderStructureInputTarget.setAttribute("value", "STOCK")
    document.dispatchEvent(new CustomEvent(MrcEvent.ORDER_FORM_SELECTED, {
      detail: {
        order_type: "stock"
      }
    }))
  }

  toggleSwitch() {
    let switchButton = this.optionSwitchTarget.querySelector(".switch-button")
    if (switchButton.classList.contains("translate-x-0")) {
      this.switchToStockForm()
    } else {
      this.switchToOptionForm()
    }
  }

  updateMarketQuote(event) {
    if (this.symbolInputTarget.value === "" || !this.symbolInputTarget.value) {
      return
    }
    if (this.updateMarketTimeout !== undefined) {
      clearTimeout(this.updateMarketTimeout)
      this.updateMarketTimeout = undefined
    }

    this.updateMarketTimeout = setTimeout(() => {
      let symbol = this.symbolInputTarget.value
      if (symbol === null || symbol === "") {
        return
      }

      let contentUrl = `/views/market-quote/${symbol}`;
      this.marketQuoteViewTarget.setAttribute("data-content-url", contentUrl)
      let loaderTag = this.marketQuoteViewTarget.getAttribute("data-loader-tag-value")
      this.marketQuoteViewTarget.setAttribute("data-preload", `false`)
      this.marketQuoteViewTarget.setAttribute("data-controller", `loader`)
      document.dispatchEvent(new CustomEvent(MrcEvent.LOAD(loaderTag)))
      const optionFormElement = this.optionFormTarget.querySelector("div[data-controller='option-form']")
      optionFormElement.setAttribute("data-symbol", symbol)
      optionFormElement.setAttribute("data-content-url", `/views/orders/option-form/${symbol}`)
      const optionFormController = this.application.getControllerForElementAndIdentifier(optionFormElement, 'option-form')
      optionFormController.updateView()
    }, 250)
  }
}
