import Controller from "./mrc_controller"
import MrcEvent from "./mrc_event";

// Connects to data-controller="watchlist"
export default class extends Controller {
  static targets = ['watchlistTable', 'watchlistAddForm', 'loader']

  updateWatchlist() {
    let loaderTagValue = this.loaderTarget.getAttribute("data-loader-tag-value")
    document.dispatchEvent(new CustomEvent(MrcEvent.LOAD(loaderTagValue)))
  }

  add(event) {
    event.preventDefault()
    event.stopPropagation()
    let symbol = this.watchlistAddFormTarget.value
    if (symbol && symbol !== "") {
      this.watchlistAddFormTarget.value = ""
      fetch(this.element.dataset.addUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ symbol: symbol }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.updateWatchlist()
        })
    }
  }

  remove(event) {
    event.preventDefault()
    event.stopPropagation()
    let symbol = event.params.symbol
    fetch(this.element.dataset.removeUrl, {
      method: 'post',
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ symbol: symbol }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.updateWatchlist()
      })
  }

  connect() {
    this.updateWatchlist() // focus prevents unecessary refresh
  }
}
