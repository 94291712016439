import React from "react";
import clsx from "clsx";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import { parseISO, formatDistanceToNow } from 'date-fns';
import Order from "./order";
import {formatMoney} from "../utils";

const ActivityList = ({orders, className}) => {
  return <>
    <div className={clsx([
      className,
      ""])}>
      <div className="flex flex-col gap-y-4">
        {orders.map((order, index) => {
          let isOpen = order.side.includes("open")
          let notional = (order.avg_fill_price || order.last_fill_price  || order.price) * order.quantity * 100
          if (order.type === "debit") {
            notional = -notional
          }
          return (
            <div key={index} className="relative flex gap-x-4">
              <div className={clsx(
                index === orders.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center',)}>
                <div className="w-px bg-zinc-800"/>
              </div>
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-zinc-900 rounded-full">
                  <div className={`h-1.5 w-1.5 rounded-full ring-1 ${isOpen ? "bg-dgreen-600 ring-dgreen-300" : "bg-rose-500 ring-rose-300" }`}/>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row">
                    <p className="flex-auto py-0.5 text-sm leading-5 text-zinc-500">
                      Robot <span className="font-medium text-zinc-300">{isOpen ? "opened" : "closed"}</span> a <span
                      className="font-medium text-dblue-200">{order.symbol}</span> trade
                    </p>
                    <div className="grow"></div>
                    <div>
                      <time dateTime={order.transaction_date} className="flex-none py-0.5 text-xs leading-5 text-zinc-500">
                        {formatDistanceToNow(parseISO(order.transaction_date), {addSuffix: true})}
                      </time>
                      <div className={`${notional > 0 ? "text-green-400" : "text-rose-400"} text-xl text-right`}>
                        {formatMoney(notional, true)}
                      </div>
                    </div>
                  </div>
                  <div className="border border-zinc-900 m-2 p-2 rounded-lg">
                    <Order order={order}/>
                  </div>
                </div>
              </>
            </div>
          );
        })}
      </div>
    </div>
  </>
}
export default ActivityList