import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offline"
export default class extends Controller {
  connect() {
    if (typeof navigator.serviceWorker !== 'undefined') {
      navigator.serviceWorker.register('/offline_page_sw.js')
    }
  }
}
