const MrcEvent = {
  OPTION_LEG_CHANGED: "mrc:option-leg-changed",
  ORDER_QUOTE_CHANGED: "mrc:order-quote-changed",
  LOAD: function(tag) {
    return `mrc:loader-load:${tag}`
  },
  ORDER_FORM_SELECTED: 'mrc:order_form_selected',
  SET_PRICE: "mrc:set_price",
  PRICE_VOLATILITY_CHART_LOAD: "mrc:price-volatility-chart:load",
  CONTENT_LOADED: "mrc:loader:load",
  SHOW_LOADING: "mrc:show-loading",
  HIDE_LOADING: "mrc:hide-loading",
  RANGE_SELECTED: "mrc:range-selected"
}

export default MrcEvent;
