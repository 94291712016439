import {Controller} from "@hotwired/stimulus";
import {StreamChat} from 'stream-chat';
import React, {useEffect} from "react";
import {createRoot} from 'react-dom/client';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import AgentChatComponent from "../components/agent_chat_component";
import ChatHead from "../components/chat_head";

const queryClient = new QueryClient()

export default class extends Controller {
  static values = {
    "dataUrl": String
  };

  connect() {

    createRoot(this.element).render((
      <QueryClientProvider client={queryClient}>
        <ChatHead dataUrl={this.dataUrlValue}/>
      </QueryClientProvider>
    ));
  }
}