import {Controller} from "@hotwired/stimulus"
import MrcEvent from "./mrc_event";
import _ from 'underscore'
// Connects to data-controller="tab"
export default class extends Controller {
  static targets = ["tabpanels", "tablist"]
  static classes = ["selected", "unselected"]

  connect() {
    Array.from(this.tablistTarget.children).forEach((child) => {
      if (child.getAttribute("aria-selected") === "true") {
        child.classList.add(...this.selectedClasses)
        this._selectTab(child)
      } else {
        child.classList.add(...this.unselectedClasses)
      }
    })
  }

  selectTab(event) {
    if (_.contains(this.tablistTarget.children, event.target)) {
      this._selectTab(event.target)
    } else {
      // We need the tab element to select it, sometimes clicks fire on the tab element children, so we find the parent here.
      this._selectTab(event.target.parentElement)
    }
  }

  _selectTab(element) {
    Array.from(this.tabpanelsTarget.children).forEach((child) => child.classList.add("hidden"))
    Array.from(this.tablistTarget.children).forEach((child) => {
      child.classList.remove(...this.selectedClasses)
      child.classList.add(...this.unselectedClasses)
    })
    let selectedTab = element
    selectedTab.classList.remove(...this.unselectedClasses)
    selectedTab.classList.add(...this.selectedClasses)
    let targetPanelId = selectedTab.getAttribute("aria-controls")
    let targetPanel = this.element.querySelector(`#${targetPanelId}`)
    if (targetPanel.classList) {
      targetPanel.classList.remove("hidden")
    }

    if (selectedTab.dataset.historyUrl) {
      history.pushState(history.state, "", selectedTab.dataset.historyUrl)
    }

    if (selectedTab.dataset.contentUrl !== undefined) {
      if (selectedTab.dataset.contentUrl) {
        targetPanel.setAttribute("data-content-url", selectedTab.dataset.contentUrl)
      }
    }

    let tag = targetPanel.dataset.loaderTagValue
    if (tag) {
      document.dispatchEvent(new CustomEvent(MrcEvent.LOAD(tag)))
    }
  }
}
