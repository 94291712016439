import React, { useState } from 'react';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from "./catalyst/fieldset";
import { Text } from "./catalyst/text";
import BotIcon from "../icons/bot_icon";
import ChevronLeftIcon from "../icons/chevron_left_icon";
import { Button } from "./catalyst/button";
import { BeakerIcon, PencilSquareIcon, PlayIcon } from "@heroicons/react/24/solid";
import OrderList from "./order_list";
import ActivityList from "./activity_list";
import PositionsList from "./position_list";
import AccountBalances from "./account_balances";
import {Dialog, DialogActions, DialogBody} from "./catalyst/dialog";
import {FetchRequest} from "@rails/request.js";
import DatePicker from "react-datepicker";

const RoboTraderView = ({ robotState = {}, setCurrentMode, data }) => {
  const [showTestDialog, setShowTestDialog] = useState(false);
  const orders = data.orders || [];

  const getRiskLabel = (risk) => {
    switch (risk) {
      case 'low_risk':
        return 'Low Risk (25% stop)';
      case 'high_risk':
        return 'High Risk (60% stop)';
      default:
        return 'Balanced (40% stop)';
    }
  };

  const handleEditClick = () => {
    setCurrentMode('edit');
  };
  const RobotTestAction = ( { setShowTestDialog }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);

    const handleTestClick = () => {
      setShowTestDialog(true);
    };

    const handleConfirmTest = async () => {
      setIsLoading(true)
      const request = new FetchRequest('post', `/robotrader/${robotState.id_token}/backtest`)
      const response = await request.perform()
      if (response.ok) {
        window.location.reload()
      } else {
        console.error('Error performing test:', response.statusText)
      }
      setIsLoading(false)
      setShowTestDialog(false)
    };

    return <>
      <Button color="light" onClick={handleTestClick}>
        <BeakerIcon />
        <span>Test</span>
      </Button>
      <Dialog onClose={() => setShowTestDialog(false)} open={showTestDialog}>
        <div className="max-w-lg">
          <Fieldset>
            <Legend className="flex flex-row items-center gap-x-2">
              <BotIcon width="60px" height="60px" />
              <div className="flex flex-col">
                <span className="text-xl md:text-xl">Confirm Test</span>
                <Text className="font-normal">Are you sure you want to test this robot?</Text>
              </div>
              <div className="grow"></div>
            </Legend>
          </Fieldset>
          <DialogBody>
            <div className="w-full bg-red flex justify-end">
              <div className="flex flex-col">
                <span className="text-zinc-300 text-sm mb-2">Pick Start Date</span>
                <DatePicker className="rounded-lg bg-zinc-900 text-zinc-400" selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
            </div>
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setShowTestDialog(false)}>
              <span className="font-normal text-zinc-500">Cancel</span>
            </Button>
            <Button disabled={isLoading} color="light" onClick={handleConfirmTest}>
              <div className="flex flex-row items-center gap-x-2">
                {isLoading && <div class="w-5 h-5 spinner"></div>}
                {isLoading ? "Processing..." : "Confirm"}
              </div>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  }

  const RobotActionHeader = () => {
    return (
      <Fieldset>
        <Legend className="flex flex-row items-center gap-x-3 pb-4 border-b border-zinc-800">
          <BotIcon width="50px" height="50px" />
          <div className="flex flex-col">
            <span className="text-xl">{robotState.name || 'RoboTrader'}</span>
            <Text className="-mt-1 font-normal">#{robotState.id_token.slice(-4)}</Text>
          </div>
          <div className="grow"></div>
          <RobotTestAction setShowTestDialog={setShowTestDialog} />
          <Button color="light" data-controller='htmx button'
                  hx-post={`/robotrader/${robotState.id_token}/run`}
                  hx-trigger="click"
                  hx-on="htmx:afterOnLoad:window.location.reload()">
            <PlayIcon />
            <span>Run</span>
          </Button>
          <Button color="light" onClick={handleEditClick}>
            <PencilSquareIcon />
            <span>Edit</span>
          </Button>
        </Legend>
      </Fieldset>
    );
  };

  const RobotStateView = () => {
    return (
      <Fieldset>
        <div className="flex flex-col mb-2 gap-y-3">
          <div className="grid grid-cols-3 gap-x-8">
            <Field>
              <Label>Ticker</Label>
              <Text>
                <div className="flex flex-row gap-x-1 items-center">
                  <img className="rounded-full overflow-hidden w-5 h-5" src={`/data-img/symbol/${robotState.ticker}`} alt={robotState.ticker} />
                  <span className="text-dblue-300 font-semibold text-base">{robotState.ticker || 'N/A'}</span>
                </div>
              </Text>
            </Field>
            <Field>
              <Label>Direction Bias</Label>
              <Text>
                <span className={`text-base ${robotState.direction === "bull" ? "text-green-400" : "text-rose-400"}`}>
                  {robotState.direction === "bull" ? "Bullish" : "Bearish" || '-'}
                </span>
              </Text>
            </Field>
            <Field>
              <Label>Hold Time</Label>
              <Text>
                <span className="text-yellow-400 text-base">{`${robotState.trade_ttl} days` || '-'}</span>
              </Text>
            </Field>
          </div>
          <Field>
            <Label>Bot Prompt</Label>
            <Text>{robotState.open_prompt || 'N/A'}</Text>
          </Field>
          <div className="grid grid-cols-2 gap-x-8">
            <Field>
              <Label>Risk Tolerance</Label>
              <Text>{getRiskLabel(robotState.risk)}</Text>
            </Field>
            <Field>
              <Label>Total Risk Amount ($)</Label>
              <Text>{robotState.total_risk || 'N/A'}</Text>
            </Field>
          </div>
        </div>
      </Fieldset>
    );
  };

  return (
    <div className="max-w-lg flex flex-col gap-y-3">
      <div className="flex items-center justify-between">
        <a data-turbo-action="advance" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400" data-headlessui-state=""
           href="/robotrader">
          <ChevronLeftIcon />
          <span>All Robots</span>
        </a>
      </div>
      <RobotActionHeader />
      <RobotStateView />
      <AccountBalances className="w-full" accountData={data.balances} />
      <PositionsList className="w-full" positions={data.positions} />
      {orders.length > 0 && <div className="flex flex-col mt-2">
        <span className="text-base text-white font-semibold">Orders</span>
        <ActivityList className="mt-2" orders={orders} />
      </div>}
    </div>
  );
};

export default RoboTraderView;